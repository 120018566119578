<template>
  <div class="flex flex-col items-center justify-center">
    <!-- videos -->
    <div
      v-show="playDetail.url"
      class="text-center w-full flex flex-col items-center justify-center animate-fadeInSlide"
    >
      <div class="text-center w-full flex flex-col items-center justify-center">
        <h5
          v-show="playDetail.url"
          class="heading2 pt-0 text-gray-500 w-full !mb-2"
        >
          {{ playDetail.title }}
        </h5>
        <h5
          v-show="playDetail.subtitle"
          class="heading3 underline underline-offset-8 decoration-wavy !text-center text-gray-500 flex flex-col"
        >
          {{ playDetail.subtitle }}
        </h5>

        <div class="Videos w-full">
          <!-- Url 1 -->
          <VideoLayout
            :url="playDetail.url"
            :author="playDetail.author"
            :language="playDetail.language"
            :duration="playDetail.duration"
            :level="playDetail.level"
            :framework="playDetail.framework"
          />
          <!-- Url 2 -->
          <VideoLayout
            :url="playDetail.url2"
            :author="playDetail.author2"
            :language="playDetail.language2"
            :duration="playDetail.duration2"
            :level="playDetail.level2"
            :framework="playDetail.framework2"
          />

          <!-- Url 3 -->
          <h5
            v-show="playDetail.subtitle3"
            class="heading3 underline underline-offset-8 decoration-wavy !text-center text-gray-500 flex flex-col"
          >
            {{ playDetail.subtitle3 }}
          </h5>

          <VideoLayout
            :url="playDetail.url3"
            :author="playDetail.author3"
            :language="playDetail.language3"
            :duration="playDetail.duration3"
            :level="playDetail.level3"
            :framework="playDetail.framework3"
          />

          <!-- Url 4 -->
          <h5
            v-show="playDetail.subtitle4"
            class="heading3 underline underline-offset-8 decoration-wavy !text-center text-gray-500 flex flex-col"
          >
            {{ playDetail.subtitle4 }}
          </h5>

          <VideoLayout
            :url="playDetail.url4"
            :author="playDetail.author4"
            :language="playDetail.language4"
            :duration="playDetail.duration4"
            :level="playDetail.level4"
            :framework="playDetail.framework4"
          />

          <!-- Url 5 -->
          <VideoLayout
            :url="playDetail.url5"
            :author="playDetail.author5"
            :language="playDetail.language5"
            :duration="playDetail.duration5"
            :level="playDetail.level5"
            :framework="playDetail.framework5"
          />

          <!-- Url 6 -->
          <h5
            v-show="playDetail.subtitle6"
            class="heading3 underline underline-offset-8 decoration-wavy !text-center text-gray-500 flex flex-col"
          >
            {{ playDetail.subtitle6 }}
          </h5>

          <VideoLayout
            :url="playDetail.url6"
            :author="playDetail.author6"
            :language="playDetail.language6"
            :duration="playDetail.duration6"
            :level="playDetail.level6"
            :framework="playDetail.framework6"
          />

          <!-- Url 7 -->
          <h5
            v-show="playDetail.subtitle7"
            class="heading3 underline underline-offset-8 decoration-wavy !text-center text-gray-500 flex flex-col"
          >
            {{ playDetail.subtitle7 }}
          </h5>

          <VideoLayout
            :url="playDetail.url7"
            :author="playDetail.author7"
            :language="playDetail.language7"
            :duration="playDetail.duration7"
            :level="playDetail.level7"
            :framework="playDetail.framework7"
          />

          <!-- Url 8 -->
          <VideoLayout
            :url="playDetail.url8"
            :author="playDetail.author8"
            :language="playDetail.language8"
            :duration="playDetail.duration8"
            :level="playDetail.level8"
            :framework="playDetail.framework8"
          />

          <!-- Url 9 -->
          <VideoLayout
            :url="playDetail.url9"
            :author="playDetail.author9"
            :language="playDetail.language9"
            :duration="playDetail.duration9"
            :level="playDetail.level9"
            :framework="playDetail.framework9"
          />
        </div>
      </div>
    </div>
    <!-- not found -->
    <div
      v-show="!playDetail.url"
      class="text-center font-header flex flex-col items-center justify-center p-10 bg-slate-100/25 rounded-md"
    >
      <div
        class="flex flex-col items-center justify-center space-y-4 text-gray-700"
      >
        <h1 class="text-2xl">
          We apologize, there is no content in this area yet.
        </h1>
        <h1 class="text-xl">We'll be adding more content soon.</h1>
        <h1 class="text-lg">
          Please check back in a little while for updates
          <span>:)</span>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";

// import EasyCircle from "@/components/icons/EasyCircle.vue";
// import MediumCircle from "@/components/icons/MediumCircle.vue";
// import AdvancedCircle from "@/components/icons/AdvancedCircle.vue";
// import MixedCircle from "@/components/icons/MixedCircle.vue";
// import NotLevel from "@/components/icons/NotLevel.vue";

// import sidebarData from "@/addData.json";
import VideoLayout from "./VideoLayout.vue";

import { useSidebarSections } from "@/views/newPlaylist/playlist";

export default {
  props: {
    id: {
      required: true,
    },
  },
  setup(props) {
    const playDetail = ref(null);
    const findTitle = (data, targetId) => {
      for (const item of data) {
        if (item.id === targetId) {
          return item;
        }
        if (item.children) {
          const foundInChildren = findTitle(item.children, targetId);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    };
    // const findAllTitles = (data) => {
    //   const allTitles = [];
    //   for (const item of data) {
    //     allTitles.push(item);
    //     if (item.children) {
    //       allTitles.push(...findAllTitles(item.children));
    //     }
    //   }
    //   return allTitles;
    // };

    const { sideBarSection } = useSidebarSections();
    const sidebarData = ref(sideBarSection);

    onBeforeMount(() => {
      playDetail.value = findTitle(sidebarData.value, parseInt(props.id));
      // const allTitles = findAllTitles(sidebarData);
      // console.log("Tüm başlıklar: ", allTitles);
    });
    return {
      playDetail,
    };
  },
  components: {
    // EasyCircle,
    // MediumCircle,
    // AdvancedCircle,
    // MixedCircle,
    // NotLevel,
    VideoLayout,
  },
};
</script>
