import store, {state} from "../store";
import {computed} from "vue";
import {useAssetActions} from "@/composables/lib/useAssetActions";
import {DELETE_ASSET, GET_ASSET, PUBLISH_ASSET, PUT_ASSET, PUT_PRIVATE_ASSET, RETRACT_ASSET} from "@/store/operations";
import {decapitalize} from "@/lib/typeHelpers/stringFunctions/capitalization";
import {capitalize} from "@/lib/typeHelpers";

const immutableParams = [
    'id',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'isDeleted',
    'isPrivate',
    'isManaged',
    'isPublic',
    'repoId',
    'dataType',
    'appId',
    'permissionHostDataType',
    'permissionHostRepoId',
    'isPermissionHost'
]

export const useDocument = (documentType, repoId, id) => {
    // todo; refactor to Class/methods
    // todo; method: loadChildDocuments
    if (!documentType) throw new Error('useDocument requires a document dataType')
    if (!repoId) throw new Error('useDocument requires an repoId')

    const alias = decapitalize(documentType)
    const document = decapitalize(alias)
    const Document = capitalize(alias)
    const loadDocument = `load${Document}`
    const updateDocument = `update${Document}`
    const publishDocument = `publish${Document}`
    const retractDocument = `retract${Document}`
    const deleteDocument = `delete${Document}`

    const context = {}

    const {dataType} = useAssetActions(documentType)

    context[document] = computed(() =>
        state.assets[dataType]?.[id] ??
        Object.values(state.assets[dataType] ?? {})
            .find(doc => doc.repoId === repoId) ?? {}
    )

    context[loadDocument] = refresh => {
        const loaded = state.assets[dataType]?.[id] ??
            Object.values(state.assets[dataType] ?? {})
                .find(doc => doc.repoId === repoId)
        if (!loaded || refresh) store.dispatch(GET_ASSET, {dataType, repoId}) // todo: request by id and repoId. controller: load optionally by id then  repoId
    }

    context[publishDocument] = () => store.dispatch(PUBLISH_ASSET, {dataType, repoId})
    context[retractDocument] = () => store.dispatch(RETRACT_ASSET, {dataType, repoId})

    context[loadDocument]()

    const normalizeParams = updated => {
        const update = {}
        Object.keys(updated).forEach(key => {
            if (!key.startsWith('_')) {
                if (!immutableParams.includes(key)) update[key] = updated[key]
            }
        })
        return update
    }
    context[updateDocument] = updated => {
        const action = context[document].value.isPrivate ? PUT_PRIVATE_ASSET : PUT_ASSET
        return store.dispatch(action, {
            dataType,
            repoId,
            update: normalizeParams(updated),
        })
    }
    context[deleteDocument] = () => store.dispatch(DELETE_ASSET, {dataType, repoId})

    return context
}
