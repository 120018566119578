<script>
export default {
  name: "ResourcesSection",
};
</script>

<template>
  <div
    class="sectionImageAndText w-full bg-gray-200 p-8 py-16 k1:py-28 bigItem flex justify-center"
  >
    <div
      class="flex flex-col k1:gap-10 gap-3 rounded k1:flex-row items-center h-full"
    >
      <div class="w-full h-full group max-w-xl flex flex-col justify-between">
        <p
          style="
            background: -webkit-linear-gradient(left, #db2777, #f97316);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          "
          class="heading2 k1:!text-5xl text-2xl"
        >
          Explore our resources
        </p>
        <div class="mt-0 k1:text-xl text-base leading-8">
          Accessible, high-quality learning materials for everyone interested in
          quantum technology
          <hr class="gradientLine" />
          <button
            class="mt-4 button main"
            @click="() => $router.push(`/playlist/page/1`)"
          >
            Learn more
            <!--            Resources-->
            <!--              Read more about EQRC-->
          </button>
        </div>
      </div>
      <img
        src="/quantum3.jpeg"
        alt="Img of quantum technology"
        class="w-full object-cover k1:h-[400px] h-[300px] rounded-2xl"
      />
    </div>
  </div>
</template>

<style scoped></style>
