<template>
    <div class="assetMemberListItem flex w-max align-middle flex-row space-x-4">
        <div class="flex justify-between p-4 items-center gap-2 flex-row">
            <Gravatar
                    :email="_user.email"
                    :size="40"
                    :default-image-src="defaultProfileImageUrl"
            ></Gravatar>
            <span
                    class="w-96 truncate"
                    :title="_user.email"
            >
                {{ _user.name || `(pending: ${_user.email})` }}
            </span>
            <div class="flex flex-row space-x-4">
                <DropdownSingle
                        :title="role ?? 'Unauthorized'"
                        :items="userRoles"
                        :showSearch="false"
                        placeholder="Employee Count"
                        @select="onRoleChange"
                ></DropdownSingle>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../../../config';
import {computed} from "vue";
import Gravatar from "@/components/gravatar/Gravatar.vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";

const {host, permissionThresholds} = config;

export default {
  name: "AssetMemberListItem.vue",
  emits: ['change'],
  components: {
    DropdownSingle,
    Gravatar,
  },
  props: {
    _user: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const defaultProfileImageUrl = `${host}/placeholders/profilePlaceholder.svg`;
    const contributionRoles = {
      'manageAsset': 'Organisation Admin',
      'developAsset': 'Accord Editor',
    }
    const role = computed(() => Object.entries(permissionThresholds)
        .reduce((role, [key, value]) => {
              if (props._user._userPermission >= value) role = contributionRoles[key]
              return role
            },
            'Unauthorised'
        )
    )
    const onRoleChange = value => {
      if (value !== role.value) {
        console.log(4443, value, contributionRoles, Object.keys(contributionRoles).find(k => contributionRoles[k] === value))
        emit('change', Object.keys(contributionRoles).find(k => contributionRoles[k] === value) || '')
      }
    }

    return {
      defaultProfileImageUrl,
      onRoleChange,
      role,
      userRoles: [...Object.values(contributionRoles), 'Unauthorised']
    }
  },
};
</script>

<style scoped></style>
