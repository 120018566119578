import {withPostfix} from "../typeHelpers/stringFunctions/prefixPostfix";

export const tailwindSpacing = { // unit: px
  0: 0,
  0.5: 2,
  1: 4,
  1.5: 6,
  2: 8,
  2.5: 10,
  3: 12,
  3.5: 14,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
  11: 44,
  12: 48,
  14: 56,
  16: 64,
  20: 80,
  24: 96,
  28: 112,
  32: 128,
  36: 144,
  40: 160,
  44: 176,
  48: 192,
  52: 208,
  56: 224,
  60: 240,
  64: 256,
  72: 288,
  80: 320,
  96: 384,
}
export const tailwindSpacingsCount = Object.keys(tailwindSpacing).length
export const getTailwindSpacing = (index, pxNotRem=true) => {
  const keys = Object.values(tailwindSpacing).sort((a, b) => a > b)
  let value = keys[index] ?? 0
  const unit = pxNotRem ? 'px' : 'rem'
  if (!pxNotRem) value = value / 4
  return withPostfix(String(value), unit)
}
