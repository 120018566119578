<template #body>
  <div
    class="sectionImageAndText w-full bg-gray-200 p-8 py-16 k1:py-28 bigItem"
  >
    <div class="flex flex-col rounded k1:flex-row items-center h-full">
      <div
        class="w-full h-full group k1:mr-20 mt-0 mb-10 k1:mb-0 mr-0 flex flex-col justify-between"
      >
        <p
          style="
            background: -webkit-linear-gradient(left, #db2777, #f97316);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          "
          class="heading2 k1:!text-5xl text-2xl"
        >
          What is the quantum readiness accord initiative?
        </p>
        <div class="mt-0 k1:text-xl text-base leading-8">
          Introducing the Quantum Readiness Accord: a streamlined initiative for
          industries and universities poised to navigate the quantum revolution.
          Gain visibility and the EQRC medal for your contributions. Click
          ‘Learn more’ to see how your organisation can join and benefit.
          <hr class="gradientLine" />
          <button
            class="mt-4 button main"
            @click="() => $router.push(`/accords/about`)"
          >
            Learn more
          </button>
        </div>
      </div>
      <img
        src="../../../public/map_europa_png.png"
        alt="map of europe"
        class="w-full object-contain k1:h-[400px] h-[300px] rounded-2xl"
      />
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "SectionImageAndText",
  methods: {
    router() {
      return router;
    },
  },
  components: {},
};
</script>

<style scoped></style>
