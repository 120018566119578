export default [
    {
        path: "/forgotpassword",
        name: "forgotpassword",
        props: true,
        component: () => import("../../components/ForgotPassword.vue"),
    },
    {
        path: "/signUp",
        name: "RegisterUserAccount",
        props: true,
        component: () =>
            import("../../components/authentication/RegisterUserAccount.vue"),
    },
    {
        path: "/activateAccount/:activateAccountToken",
        props: true,
        component: () =>
            import("../../views/authentication/ActivateAccountWithToken.vue"),
    },
    {
        path: "/acceptInvitation/:acceptInvitationToken",
        props: true,
        component: () =>
            import("../../views/authentication/AcceptInvitationWithToken.vue"),
    },
    {
        path: "/resetPassphrase/:resetPassphraseToken",
        props: true,
        component: () =>
            import("../../views/authentication/ResetPassphraseWithToken.vue"),
    },
    {
        path: "/changeAccountEmail/:changeAccountEmailToken",
        props: true,
        component: () =>
            import("../../views/authentication/ChangeAccountEmailWithToken.vue"),
    },
    {
        path: "/activateNewEmail/:activateNewEmailToken",
        props: true,
        component: () =>
            import("../../views/authentication/ActivateNewEmailWithToken.vue"),
    },
    {
        path: "/downloadMyData/:downloadMyDataToken",
        props: true,
        component: () =>
            import("../../views/authentication/DownloadMyDataWithToken.vue"),
    },
    {
        path: "/forgetAboutMe/:forgetAboutMeToken",
        props: true,
        component: () =>
            import("../../views/authentication/ForgetAboutMeWithToken.vue"),
    },
]