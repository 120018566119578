import { ref } from "vue";

export function useSidebarSections() {
  const sideBarSection = ref([
    {
      "id": 0,
      "name": "Introduction",
      "slug": "introduction",
      "title": "Introduction to quantum physics/Concepts and foundations",
      "children": [
        {
          "id": 1,
          "name": "QuantumNutshell",
          "slug": "quantum-nutshell",
          "title": "Quantum physics in a nutshell",
          "url":
            "https://www.youtube.com/embed/gAFAj3pzvAA?si=HflUktncAPb5KtO2",
          "author": "Domain of science",
          "level": "Easy",
          "duration": "21 min",
          "language": "ENG",
          "framework": "1.1",
          "url2":
            "https://www.youtube.com/embed/9XR7bskHSlI?si=nwayywfcI5HM68pn",
          "author2": "Chicago quantum exchange",
          "level2": "Easy",
          "duration2": "4 min",
          "language2": "ENG",
          "framework2": "1.2",
        },
        {
          "id": 2,
          "name": "WaveParticle",
          "slug": "wave-particle",
          "title": "Wave-particle duality",
          "url":
            "https://www.youtube.com/embed/J1yIApZtLos?si=iGH_BO-sNjAvWyPQ",
          "author": "TED ed",
          "level": "Easy",
          "duration": "4 min",
          "language": "ENG",
          "framework": "1.1",
        },
        {
          "id": 3,
          "name": "Superposition",
          "slug": "superposition",
          "title": "Superposition principle",
          "url":
            "https://www.youtube.com/embed/YPlAWN3k6So?si=UTgk6cAVXEobZTEd",
          "author": "La physique autrement",
          "level": "Medium",
          "duration": "3 min",
          "language": "FR",
          "framework": "1.1",
          "url2":
            "https://www.youtube.com/embed/WGj5b6-gGjA?si=AC1OJvP-3V8cjOrs",
          "author2": "Chicago quantum exchange",
          "level2": "Easy",
          "duration2": "6 min",
          "language2": "ENG",
          "framework2": "1.1",
        },
        {
          "id": 4,
          "name": "Entanglement",
          "slug": "entanglement",
          "title": "Entanglement",
          "url":
            "https://www.youtube.com/embed/OmVj7tNc3kY?si=3A4-AMmNLlrNKpQN",
          "author": "Institut d'optique",
          "level": "Easy",
          "duration": "8 min",
          "language": "FR",
          "framework": "1.1",
        },
        {
          "id": 5,
          "name": "Qubits",
          "slug": "qubits",
          "title": "Qubits",
          "url":
            "https://www.youtube.com/embed/zNzzGgr2mhk?si=ZrCmYSTlTViY6LIZ",
          "author": "Veritasium",
          "level": "Easy",
          "duration": "7 min",
          "language": "ENG",
          "framework": "4.2",
        },
        {
          "id": 6,
          "name": "Quantumalgorithms",
          "slug": "quantum-algorithms",
          "title": "Quantum algorithms",
          "url":
            "https://www.youtube.com/embed/-ysVGWtAjio?si=ZgdQFnDpgiU99Jg7",
          "author": "IBM research",
          "level": "Easy",
          "duration": "3 min",
          "language": "ENG",
          "framework": "5.5",
          "url2":
            "https://www.youtube.com/embed/6qD9XElTpCE?si=3PleSV6aMoWExTQN",
          "author2": "Qiskit",
          "level2": "Medium",
          "duration2": "30 min",
          "language2": "ENG",
          "framework2": "5.5",
        },
        {
          "id": 7,
          "name": "Quantumteleportation",
          "slug": "quantum-teleportation",
          "title": "Quantum teleportation",
          "url":
            "https://www.youtube.com/embed/cSubSa3I3f4?si=OQ2SB1_hyH96xw75",
          "author": "Anastasia Marchenkova",
          "level": "Medium",
          "duration": "9 min",
          "language": "ENG",
          "framework": "7.1",
          "url2":
            "https://www.youtube.com/embed/lbrO_0EImZ4?si=uMRVI_AXkU8ASqsd",
          "author2": "Artur Ekert",
          "level2": "Advanced",
          "duration2": "9 min",
          "language2": "ENG",
          "framework2": "7.1",
        },
        {
          "id": 8,
          "name": "Atomicclocks",
          "slug": "atomic-clocks",
          "title": "Atomic clocks",
          "url":
            "https://www.youtube.com/embed/3WiA_Re7-RE?si=OvgcCAyJcAnr5TnA",
          "author": "centre for quantum technology",
          "level": "Easy",
          "duration": "3 min",
          "language": "ENG",
          "framework": "6.6",
          "url2":
            "https://www.youtube.com/embed/_5sTxL-vvGU?si=i1dDWGRZTa42QInJ",
          "author2": "Motherboard",
          "level2": "Easy",
          "duration2": "7 min",
          "language2": "ENG",
          "framework2": "6.6",
        },
        {
          "id": 9,
          "name": "Spin",
          "slug": "spin",
          "title": "Spin",
          "url":
            "https://www.youtube.com/embed/tPG3y6ykJnQ?si=oJA4WuRzhkGKr89B",
          "author": "UNSW Australia",
          "level": "Easy",
          "duration": "2 min",
          "language": "ENG",
          "framework": "1.1",
        },
      ],
    },
    {
      "name": "EnablingTechnologies",
      "slug": "enabling-technologies",
      "title": "Enabling Technologies",
      "id": 10,
      "children": [
        {
          "id": 11,
          "name": "Solidstate",
          "slug": "solid-state",
          "title": "Solid state and quantum technologies",
          "url":
            "https://www.youtube.com/embed/N1y2DurBFCI?si=YfubLJun2yplCOsf",
          "author": "QuTech academy",
          "level": "Easy",
          "duration": "9 min",
          "language": "ENG",
          "framework": "3.2",
          // "url2":
          //   "https://www.youtube.com/embed/2DDzOZIRw1c?si=QiJ3H1FsRkTZQ205",
          // "author2": "Qsense",
          // "level2": "Advanced",
          // "duration2": "2 hours",
          // "language2": "ENG",
          // "framework2": "3.2",
        },
        {
          "id": 12,
          "name": "Opticaltechnologies",
          "slug": "optical-technologies",
          "title": "Optical technologies",
          "url":
            "https://www.youtube.com/embed/3eTogq7rknQ?si=ar5vkyc0TWfPtmBS",
          "author": "Wired",
          "level": "Mixed",
          "duration": "24 min",
          "language": "ENG",
          "framework": "3.3",
          "url2":
            "https://www.youtube.com/embed/Vt84rSJa7VI?si=zw-sO0shdkI0GyIS",
          "author2": "institute for quantum computing",
          "level2": "Medium",
          "duration2": "1 hour 25 min",
          "language2": "ENG",
          "framework2": "3.3",
        },
        {
          "id": 13,
          "name": "Computerssoftwares",
          "slug": "computers-softwares",
          "title": "Computers and softwares",
          "url":
            "https://www.youtube.com/embed/tw4IGQPgIQk?si=UiaEs-PGihzl5Fc8",
          "author": "Girls in quantum",
          "level": "Advanced",
          "duration": "1 hour",
          "language": "ENG",
          "framework": "3.5",
          "url2":
            "https://www.youtube.com/embed/ChABF4RR9rY?si=_v_UYY_-r40TOqrQ",
          "author2": "Quiskit",
          "level2": "Advanced",
          "duration2": "1 hour",
          "language2": "ENG",
          "framework2": "3.3",
        },
        {
          "id": 14,
          "name": "Laboratorytechniques",
          "slug": "laboratory-techniques",
          "title": "Laboratory techniques",
        },
      ],
    },
    {
      "name": "QuantumTechnology",
      "slug": "quantum-technology",
      "title": "Quantum Technology Pillars",
      "id": 15,
      "children": [
        {
          "id": 16,
          "name": "QuantumTechnologyPillars",
          "slug": "quantum-technology-pillars",
          "title": "Quantum Technology Pillars",
          "url":
            "https://www.youtube.com/embed/pwOWqyGixpU?si=Jbm0FfC4eti0MoAm",
          "author": "Quantum flagship",
          "level": "Advanced",
          "duration": "1 hour 20 min",
          "language": "ENG",
          "framework": "5.6/-/7.6",
          "children": [
            {
              "name": "QuantumComputing",
              "slug": "quantum-computing",
              "title": "Quantum Computing",
              "id": 17,
              "children": [
                {
                  "id": 18,
                  "name": "QuantumComputingCrashCourse",
                  "slug": "quantum-computing-crash-course",
                  "title": "Crash course in Q.comp.",
                  "url":
                    "https://www.youtube.com/embed/-UlxHPIEVqA?si=08bR5T3TqMEgfLSZ",
                  "author": "Domain of science",
                  "level": "Easy",
                  "duration": "33 min",
                  "language": "ENG",
                  "framework": "5.1",
                },
                {
                  "id": 19,
                  "name": "QuantumComputingUseCases",
                  "slug": "quantum-computing-use-cases",
                  "title": "Use cases",
                  "url":
                    "https://www.youtube.com/embed/zOGNoDO7mcU?si=p3MiDXihvOXUTq1B",
                  "author": "IBM research",
                  "level": "Medium",
                  "duration": "28 min",
                  "language": "ENG",
                  "framework": "4.1-4.6-5.1-5.6",
                },
                {
                  "id": 20,
                  "name": "EnablingConcepts",
                  "slug": "enabling-concepts",
                  "title": "Enabling Concepts",
                  "subtitle": "Quantum Annealing",
                  "url":
                    "https://www.youtube.com/embed/OQ91L96YWCk?si=ffZlSy4TWKK_RAtv",
                  "author": "Google tech talks",
                  "level": "Advanced",
                  "duration": "50 min",
                  "language": "ENG",
                  "framework": "5.2",
                  "url2":
                    "https://www.youtube.com/embed/GhRpwOT3y7Q?si=8QHWkCTi9gEZNx2s",
                  "author2": "World science festival",
                  "level2": "Medium",
                  "duration2": "2 min",
                  "language2": "ENG",
                  "framework2": "5.2",
                  "url3":
                    "https://www.youtube.com/embed/zvfkXjzzYOo?si=KFUrm01JOs6cDO9F",
                  "author3": "D-wave",
                  "level3": "Easy",
                  "duration3": "6 min",
                  "language3": "ENG",
                  "framework3": "5.2",
                  "url4":
                    "https://www.youtube.com/embed/UV_RlCAc5Zs?si=hZZzZbmnIAB7nKcp",
                  "author4": "D-wave",
                  "level4": "Easy",
                  "duration4": "6 min",
                  "language4": "ENG",
                  "framework4": "5.2",
                  "url5":
                    "https://www.youtube.com/embed/awpnsGl08bc?si=ErxF7vtPnvI9I0NC",
                  "author5": "MIT opencourse ware",
                  "level5": "Advanced",
                  "duration5": "1 hour 22 min",
                  "language5": "ENG",
                  "framework5": "1.1",
                },
                {
                  "id": 21,
                  "name": "ExperimentalPlatforms",
                  "slug": "experimental-platforms",
                  "title": "Experimental Platforms",
                  "subtitle": "Trapped atoms and ions",
                  "url":
                    "https://www.youtube.com/embed/lJOuPmI--5c?si=cBBvh6Su6S6ezJVf",
                  "author": "MIT opencourse",
                  "level": "Advanced",
                  "duration": "39 min",
                  "language": "ENG",
                  "framework": "4.3",
                  "url2":
                    "https://www.youtube.com/embed/uNTNsfxoKYQ?si=7aueh9wVc6MeguW3",
                  "author2": "ETH",
                  "level2": "Advanced",
                  "duration2": "1 hour 20 min",
                  "language2": "ENG",
                  "framework2": "4.8",
                  "subtitle3": "Gate based quantum computing",
                  "url3":
                    "https://www.youtube.com/embed/QXJ96Kyt6TA?si=rXvAg6DVtp3oPyyk",
                  "author3": "IBM research",
                  "level3": "Easy",
                  "duration3": "2 min",
                  "language3": "ENG",
                  "framework3": "4.6",
                  "subtitle4": "Super conducting qubits",
                  "url4":
                    "https://www.youtube.com/embed/Pjq2YUNtbAo?si=ERAvBDMneO7OSGTK",
                  "author4": "ETH",
                  "level4": "Advanced",
                  "duration4": "2 hour",
                  "language4": "ENG",
                  "framework4": "4.1",
                  "url5":
                    "https://www.youtube.com/embed/uPw9nkJAwDY?si=UE0PKiPNrrwCWFRu",
                  "author5": "quantumcast",
                  "level5": "Medium",
                  "duration5": "10 min",
                  "language5": "ENG",
                  "framework5": "4.1",
                  "subtitle6": "Photonic Circuits",
                  "url6":
                    "https://www.youtube.com/embed/DswQ0FTYa18?si=xz6YAGPaHT-wLNED",
                  "author6": "Wien University",
                  "level6": "Advanced",
                  "duration6": "1 hour",
                  "language6": "ENG",
                  "framework6": "4.4",
                  "subtitle7": "Spin Based",
                  "url7":
                    "https://www.youtube.com/embed/tPG3y6ykJnQ?si=C8dg7YKoWHgojVnQ",
                  "author7": "UNSW Australia",
                  "level7": "Easy",
                  "duration7": "2 min",
                  "language7": "ENG",
                  "framework7": "1.1",
                  "url8":
                    "https://www.youtube.com/embed/Re4l22ycc-k?si=sPkSrhlogI6a68hE",
                  "author8": "QuTech academy",
                  "level8": "Medium",
                  "duration8": "11 min",
                  "language8": "ENG",
                  "framework8": "4.2",
                  "url9":
                    "https://www.youtube.com/embed/VdLbG8XH3RY?si=whfmkaxvW6Q7ZTt_",
                  "author9": "Chicago quantum exchange",
                  "level9": "Easy",
                  "duration9": "6 min",
                  "language9": "ENG",
                  "framework9": "4.2",
                },
              ],
            },
            {
              "id": 21,
              "name": "QuantumSimulation",
              "slug": "quantum-simulation",
              "title": "Quantum Simulation",
              "children": [
                {
                  "id": 23,
                  "name": "CrashcourseinqSimulation",
                  "slug": "crash-course-in-q-simulation",
                  "title": "Crash course in Q simulation",
                  "url":
                    "https://www.youtube.com/embed/SFjokTmGL8o?si=hFDghvpkmXfiGZtp",
                  "author": "Serious science",
                  "level": "Easy",
                  "duration": "12 min",
                  "language": "ENG",
                  "framework": "5.1/5.6",
                  "url2":
                    "https://www.youtube.com/embed/kD370qyxTyw?si=ciXu3Uzu0FvNhuu0",
                  "author2": "Domain of science",
                  "level2": "Easy",
                  "duration2": "13 min",
                  "language2": "ENG",
                  "framework2": "5.1",
                },
                {
                  "id": 24,
                  "name": "QuantumSimulationUsecases",
                  "slug": "quantum-simulation-use-cases",
                  "title": "Use Cases",
                  "url":
                    "https://www.youtube.com/embed/QH_QmuHZ894?si=-x2NGJZBO3aLrI3n",
                  "author": "Qiskit",
                  "level": "Advanced",
                  "duration": "33 min",
                  "language": "ENG",
                  "framework": "5.6",
                },
                {
                  "id": 25,
                  "name": "ExperimentalPlatformsII",
                  "slug": "experimental-platforms-ii",
                  "title": "Experimental Platforms",
                  "subtitle": "Cold atoms and ions",
                  "url":
                    "https://www.youtube.com/embed/hw3Ql1v8wRA?si=ZnOIA_PRjtrt91dS",
                  "author": "Serious science",
                  "level": "Easy",
                  "duration": "12 min",
                  "language": "ENG",
                  "framework": "5.1/5.6",
                  "url2":
                    "https://www.youtube.com/embed/mdP45NtC65w?si=DAuD63drzBGJlUO-",
                  "author2": "APS Physics",
                  "level2": "Medium",
                  "duration2": "35 min",
                  "language2": "ENG",
                  "framework2": "4.3",
                  "subtitle3": "Photonic Devices",
                  "url3":
                    "https://www.youtube.com/embed/U1Ja1DE_FEQ?si=L4QSTkDnhVAfnAfw",
                  "author3": "Northwest quantum network",
                  "level3": "Advanced",
                  "duration3": "1 hour",
                  "language3": "ENG",
                  "framework3": "4.4",
                },
              ],
            },
            {
              "id": 26,
              "name": "QuantumCommuniciations",
              "slug": "quantum-communiciations",
              "title": "Quantum Communiciations",
              "children": [
                {
                  "id": 27,
                  "name": "CrashCourseinQcomms",
                  "slug": "crash-course-inq-comms",
                  "title": "Crash course in Qcomms",
                  "url":
                    "https://www.youtube.com/embed/hV_yqVNa0kc?si=lwgQ-LGUqqayyTiL",
                  "author": "CQC2T",
                  "level": "Advanced",
                  "duration": "40 min",
                  "language": "ENG",
                  "framework": "7.1",
                },
                {
                  "id": 28,
                  "name": "QuantumCommuniciationsUsecases",
                  "slug": "quantum-communiciations-use-cases",
                  "title": "Use cases",
                },
                {
                  "id": 29,
                  "name": "EnablingConceptsII",
                  "slug": "enabling-concepts-ii",
                  "title": "Enabling Concepts",
                  "children": [
                    {
                      "id": 30,
                      "name": "QuantumTeleportationII",
                      "slug": "quantum-teleportation-ii",
                      "title": "Quantum Teleportation",
                      "subtitle": "Quantum Number Generators",
                      "url":
                        "https://www.youtube.com/embed/lcaAtYi4VD4?si=3QlstifAZmlOyoRu",
                      "author": "Quantum Flagship",
                      "level": "Easy",
                      "duration": "2 min",
                      "language": "ENG",
                      "framework": "7.2",
                      "url2":
                        "https://www.youtube.com/embed/gEghPHxuk_Q?si=kBrvLxpV9Pn6w6Ca",
                      "author2": "ID Quantique",
                      "level2": "Easy",
                      "duration2": "2 min",
                      "language2": "ENG",
                      "framework2": "7.2",
                    },
                    {
                      "id": 31,
                      "name": "Qkd",
                      "slug": "qkd",
                      "title": "QKD",
                      "url":
                        "https://www.youtube.com/embed/8hNQyTdNil4?si=0DJg2SiSMKzIjzec",
                      "author": "Tuftel Akademie",
                      "level": "Easy",
                      "duration": "5 min",
                      "language": "ENG",
                      "framework": "7.3",
                      "url2":
                        "https://www.youtube.com/embed/lVXJgn3fDkg?si=RKGpe65P0wu9CjH9",
                      "author2": "Qu Tech Academy",
                      "level2": "Medium",
                      "duration2": "7 min",
                      "language2": "ENG",
                      "framework2": "7.3",
                    },
                    {
                      "id": 32,
                      "name": "QuantumRepeaters",
                      "slug": "quantum-repeaters",
                      "title": "Quantum Repeaters",
                      "url":
                        "https://www.youtube.com/embed/9iCFH9Fk184?si=RZpaCvOHMdPyl7ws",
                      "author": "Qu Tech Academy",
                      "level": "Easy",
                      "duration": "4 min",
                      "language": "ENG",
                      "framework": "7.6",
                      "url2":
                        "https://www.youtube.com/embed/yEuWyta9O6Y?si=TIkHpydp9MAsQ-r3",
                      "author2": "ICFO",
                      "level2": "Easy",
                      "duration2": "5 min",
                      "language2": "ESP-ENG",
                      "framework2": "7.6",
                      "url3":
                        "https://www.youtube.com/embed/niHe_HyluJ0?si=k6woyV8F5VFZXBAX",
                      "author3": "Q-Leap Edu Quantum Comms",
                      "level3": "Advanced",
                      "duration3": "5 min",
                      "language3": "ENG",
                      "framework3": "7.6",
                    },
                  ],
                },
                {
                  "id": 33,
                  "name": "ExperimentalPlatformsIII",
                  "slug": "experimental-platforms-iii",
                  "title": "Experimental Platforms",
                  "children": [
                    {
                      "id": 34,
                      "name": "PhotonicDevicesII",
                      "slug": "photonic-devices-ii",
                      "title": "Photonic Devices",
                      "url":
                        "https://www.youtube.com/embed/4QlcKuxDGrs?si=kjW1UK7ok-Bmyvsy",
                      "author": "Secience Magazine",
                      "level": "Easy",
                      "duration": "3 min",
                      "language": "ENG",
                      "framework": "7.5",
                    },
                    {
                      "id": 35,
                      "name": "SpaceCommunications",
                      "slug": "space-communications",
                      "title": "Space Communications",
                      "url":
                        "https://www.youtube.com/embed/oxhiKSGxT6I?si=T9jlBUhv3_dPQhR_",
                      "author": "Qureca",
                      "level": "Advanced",
                      "duration": "30 min",
                      "language": "ENG",
                      "framework": "7.5",
                    },
                  ],
                },
              ],
            },
            {
              "id": 36,
              "name": "QuantumSensing",
              "slug": "quantum-sensing-metrology",
              "title": "Quantum Sensing & Metrology",
              "children": [
                {
                  "id": 37,
                  "name": "QuantumSensingCrashCourse",
                  "slug": "quantum-sensing-crash-course",
                  "title": "Quantum Sensing Crash Course",
                  "url":
                    "https://www.youtube.com/embed/sqACWPrbEd0?si=yql3melhgdyW5Md1",
                  "author": "IQC",
                  "level": "Advanced",
                  "duration": "1 hour",
                  "language": "ENG",
                  "framework": "6.2/6.3/6.4/6.5",
                  "url2":
                    "https://www.youtube.com/embed/3ccJeo_yvA8?si=l59KYwFsJb1UFs-y",
                  "author2": "Quantum Flagship",
                  "level2": "Advanced",
                  "duration2": "25 min",
                  "language2": "ENG",
                  "framework2": "6.1/6.5/7.3/7.5",
                },
                {
                  "id": 38,
                  "name": "QuantumSensingUsecases",
                  "slug": "quantum-sensing-use-cases",
                  "title": "Quantum Sensing Use Cases",
                  "url":
                    "https://www.youtube.com/embed/-HXz5lFFFGg?si=Amqs7rLsymwUWgx0",
                  "author": "Tuftel Akademie",
                  "level": "Easy",
                  "duration": "3 min",
                  "language": "ENG",
                  "framework": "6.7",
                  "url2":
                    "https://www.youtube.com/embed/vRwCFM52l_c?si=Gz4d_DuLRHRLhu-K",
                  "author2": "Quantum Flagship",
                  "level2": "Advanced",
                  "duration2": "32 min",
                  "language2": "ENG",
                  "framework2": "6.3-4.2-4.8-6.2-6.7",
                  "url3":
                    "https://www.youtube.com/embed/KHkfTTaQF9I?si=SFNvDXNxFGTDYc6C",
                  "author3": "EPIC Photonics",
                  "level3": "Medium",
                  "duration3": "1 hour 40 min",
                  "language3": "ENG",
                  "framework3": "6.7",
                },
                {
                  "id": 39,
                  "name": "EnablingTecnologiesConcept",
                  "slug": "enabling-tecnologies-concept",
                  "title": "Enabling Tecnologies Concept",
                  "children": [
                    {
                      "id": 40,
                      "name": "QuantumImaging",
                      "slug": "quantum-imaging",
                      "title": "Quantum Imaging",
                      "url":
                        "https://www.youtube.com/embed/jufLE47OxSg?si=oe1onRp6_ryTqibJ",
                      "author": "Centre for quantum technologies",
                      "level": "Advanced",
                      "duration": "1 hour",
                      "language": "ENG",
                      "framework": "6.5, 6.1",
                    },
                    {
                      "id": 41,
                      "name": "QuantumAnnealingConcept",
                      "slug": "quantum-annealing-concept",
                      "title": "Quantum Annealing Concept",
                      "url":
                        "https://www.youtube.com/embed/eocHsq2FevY?si=UnaMhiCtlKkK3wZT",
                      "author": "Libreum research institute",
                      "level": "Advanced",
                      "duration": "7 min",
                      "language": "ENG",
                      "framework": "5.2",
                    },
                    {
                      "id": 42,
                      "name": "NvCenters",
                      "slug": "nv-centers",
                      "title": "Nv Centers",
                      "url":
                        "https://www.youtube.com/embed/dl4nyCXhOr4?si=LaTjTcJxo5jH8vwK",
                      "author": "QuSco",
                      "level": "Easy",
                      "duration": "8 min",
                      "language": "ENG",
                      "framework": "6.2",
                      "url2":
                        "https://www.youtube.com/embed/VCT0wDLyvSs?si=y9dOsya6WhXs2T8m",
                      "author2": "Nature Video",
                      "level2": "Easy",
                      "duration2": "6 min",
                      "language2": "ENG",
                      "framework2": "6.2/6.7",
                      "url3":
                        "https://www.youtube.com/embed/VdLbG8XH3RY?si=SS-2DJ6ueglv3qyg",
                      "author3": "Chiago Quantum Exchange",
                      "level3": "Easy",
                      "duration3": "6 min",
                      "language3": "ENG",
                      "framework3": "6.2/6.8",
                    },
                  ],
                },
              ],
            },
            {
              "id": 43,
              "name": "QuantumInformationTheory",
              "slug": "quantum-information-theory",
              "title": "Quantum Information Theory",
              "children": [
                {
                  "id": 44,
                  "name": "QuantumInformationTheoryCrashCourse",
                  "slug": "quantum-information-theory-crash-course",
                  "title": "Crash Course",
                  "url":
                    "https://www.youtube.com/embed/B5BUhzBlO-U?si=et5SYYi27M8zWSV6",
                  "author": "Qiskit",
                  "level": "Medium",
                  "duration": "25 min",
                  "language": "ENG",
                  "framework": "1.1/1.2/5.1/7.3",
                  "url2":
                    "https://www.youtube.com/embed/Q4xBlSi_fOs?si=XgRW-LCtUyjSsM9J",
                  "author2": "Quantum Flagship",
                  "level2": "Advanced",
                  "duration2": "1 hour",
                  "language2": "ENG",
                  "framework2": "1.1/5.1/5.5/7.1/7.3",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      "id": 45,
      "name": "OtherTopics",
      "slug": "other-topics",
      "title": "Other Topics",
      "children": [
        {
          "id": 46,
          "name": "Teaching",
          "slug": "teaching",
          "title": "Teaching",
          "url":
            "https://www.youtube.com/embed/RRMi9IW_-Jc?si=jSXe2t_opH-EvKhk",
          "author": "QusCo european training",
          "level": "Easy",
          "duration": "6 min",
          "language": "ENG",
          "framework": "6.4",
        },

        {
          "id": 47,
          "name": "InnovationEntrepreneurship",
          "slug": "innovation-entrepreneurship",
          "title": "Innovation Entrepreneurship",
          "url":
            "https://www.youtube.com/embed/Vtl3oe_w0us?si=F33A3WGIXPYGtEoP",
          "author": "QuantaGENO MICS",
          "level": "Easy",
          "duration": "1 hour 30 min",
          "language": "ENG",
          "framework": "8",
          "url2": "https://quantera.eu/about/",
          "author2": "Quantera",
          "level2": "Medium",
          "duration2": "3+3 min",
          "language2": "ENG",
          "framework2": "8.2",
          "url3":
            "https://www.youtube.com/embed/sheIkirO778?si=Gnk_JlKboNyjn960",
          "author3": "Quantum Flagship",
          "level3": "Easy",
          "duration3": "3 min",
          "language3": "ENG",
          "framework3": "8",
        },
      ],
    },
  ]);

  return {
    sideBarSection,
  };
}
