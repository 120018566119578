import {
    GET_CLIENT_ID,
    LIST_ASSETS,
    GET_ASSET,
    DELETE_ASSET,
    LOGIN,
    LOGOUT,
    RESUME_SESSION,
    _PUT_STATUS, _RESET_ASSETS,
} from './operations.js'

import {isStatusVariable, useDataType} from "@/store/lib/assets";

const upsertLocalAssets = (state, payload) =>
    Object.keys(payload)
        .filter(key => !isStatusVariable(key))
        .forEach(dataType => {
            useDataType(state, dataType, true)
                .pullAssets(payload[dataType])
        })

export default {
    [LIST_ASSETS]: upsertLocalAssets,
    [GET_ASSET]: upsertLocalAssets,
    [DELETE_ASSET]: upsertLocalAssets,
    [GET_CLIENT_ID](state, {User: items}) {
        const item = items[0]
        state.clientId = item.id
    },
    [RESUME_SESSION](state) {
        state.user = JSON.parse(localStorage.getItem('user') ?? 'null')
        state.account = JSON.parse(localStorage.getItem('account') ?? 'null')
    },
    [LOGIN](state, {User, Account, _isDevOp, _administratingApps}) {
        state.user = User?.[0] ?? null
        if (_isDevOp) state.user.__isDevop = _isDevOp
        if (_isDevOp || _administratingApps) state.user.__isAppAdmin = _isDevOp || _administratingApps.includes(state.appId)
        state.account = Account?.[0] ?? null
        localStorage.setItem('user', JSON.stringify(state.user))
        localStorage.setItem('account', JSON.stringify(state.account))
    },
    [LOGOUT](state) {
        state.user = null
        state.account = null
        localStorage.removeItem('user', null)
        localStorage.removeItem('account', null)
        window.location.href = "/";
    },
    [_PUT_STATUS](state, {event, result}) {
        const error = result?.error ?? result?.Error

        let status = null
        if (error) {
            status = {
                isError: true,
                body: error.message,
                timestamp: Date.now()
            }
        } else if (result?._status) {
            status = {
                isSuccess: !result._isError,
                isError: result._isError,
                body: result._status,
                timestamp: Date.now()
            }
        }
        if (status) {
            // console.log(33, event, error, status)
            state.status[event] = status
        }
    },
    [_RESET_ASSETS](state) {
        Object.keys(state.assets).forEach(key => delete state.assets[key])
    }
}
