<template>
    <div class="appUserListItem flex w-max align-middle flex-row space-x-4">
        <div class="flex justify-between p-4 items-center gap-2 flex-row">
            <Gravatar
                    :email="_user.email"
                    :size="40"
                    :default-image-src="defaultProfileImageUrl"
            ></Gravatar>
            <span
                    class="w-96 truncate"
                    :title="_user.email"
            >
                {{ _user.name || `(pending: ${_user.email})` }}
            </span>
            <div class="flex flex-row space-x-4">
                <DropdownSingle
                        :title="role ?? 'Unauthorized'"
                        :items="userRoles"
                        :showSearch="false"
                        placeholder="Employee Count"
                        @select="onRoleChange"
                ></DropdownSingle>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../../../config';
import {computed} from "vue";
import Gravatar from "@/components/gravatar/Gravatar.vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";
import {useUserPermissions} from "@/composables/useUserPermissions";

const {permissionThresholds} = config;

const appRoles = [
    'Member',
    'Manager',
]
export default {
    name: "AppUserListItem",
    emits: ['change'],
    components: {
        DropdownSingle,
        Gravatar,
    },
    props: {
        _user: {
            type: Object,
            required: true,
        },
    },
    setup(props, {emit}) {
        const defaultProfileImageUrl = `/placeholders/profilePlaceholder.png`;
        const {roles} = useUserPermissions()
        const availableUserRoles = computed(() =>
            appRoles
                .filter(k => roles.value.includes(k))
        )

        const role = computed(() => {
                let role = Object.entries(permissionThresholds)
                    .reduce((role, [key, value]) => {
                            if (value === props._user._userPermission) role = key
                            return role
                        },
                        'Unauthorised'
                    )
                role = availableUserRoles[role]
                return role
            }
        )


        return {
            defaultProfileImageUrl,
            onRoleChange: role => emit('change', role),
            role,
            userRoles: [...availableUserRoles.value, 'Unauthorised']
        }
    },
};
</script>

<style scoped></style>
