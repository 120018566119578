<template>
  <footer class="bg-indigo-950 text-gray-400">
    <LSection>
      <template #body>
        <div class="w-full">
          <div
            class="flex items-center justify-center k1:gap-10 gap-4 k1:pt-12 pt-10"
          >
            <Linkdin />
            <a
              href="https://twitter.com/euquantumready"
              class="p-1.5 border border-slate-400 bg-white/10 rounded-md hover:bg-white/20"
            >
              <img
                src="/x-social-media-black-icon.png"
                alt=""
                class="w-6 h-6"
              />
            </a>
          </div>
          <div class="w-full text-center flex justify-center py-5">
            <a
              class="text-sm underline cursor-pointer hover:text-white"
              href="/contact"
            >
              Contact
            </a>
          </div>
          <div
            class="container mx-auto pb-10 grid px-20 h6:px-0 grid-cols-1 gap-6 h8:grid-cols-3 place-items-center items-center h8:gap-0"
          >
            <img src="../../../public/logo.svg" alt="EQRC Logo" class="h-12" />
            <div class="flex gap-1 w-48">
              <img
                src="../../assets/images/euflag.1d437774.png"
                alt="EU Flag"
                class="w-16 object-contain h-12"
              />
              <p class="text-xs">
                This project has received funding from the European Union.
              </p>
            </div>
            <div class="flex-1 flex-col">
              <div class="flex">
                <label class="text-xs"
                  >Check
                  <a
                    class="text-blue-600 hover:underline text-xs"
                    href="/PrivacyPolicyAgreement.pdf"
                    target="_blank"
                  >
                    Privacy Policy</a
                  ></label
                >
              </div>
              <div class="flex">
                <label class="text-xs"
                  >Check
                  <a
                    class="text-blue-600 hover:underline text-xs"
                    href="/PersonalDataAgreement.pdf"
                    target="_blank"
                  >
                    Terms and Conditions</a
                  ></label
                >
              </div>
              <div class="flex">
                <label class="text-xs">
                  Check
                  <a
                    class="text-blue-600 hover:underline text-xs"
                    href="/CookiePolicy.pdf"
                    target="_blank"
                  >
                    Cookie Policy
                  </a>
                  <!--                  <svg-->
                  <!--                    xmlns="http://www.w3.org/2000/svg"-->
                  <!--                    viewBox="0 0 30 30"-->
                  <!--                    width="15px"-->
                  <!--                    height="15px"-->
                  <!--                    class=""-->
                  <!--                  >-->
                  <!--                    <path-->
                  <!--                      fill="#ffffff"-->
                  <!--                      d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"-->
                  <!--                    />-->
                  <!--                  </svg>-->
                </label>
              </div>
            </div>
          </div>
        </div>
      </template>
    </LSection>
    <!--    <div class="py-4">-->
    <!--      <div class="container mx-auto text-center text-sm">-->
    <!--        <p>-->
    <!--          &copy; European Quantum Readiness Center 2023. All rights reserved.-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->
  </footer>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import Linkdin from "@/components/layout/Linkdin.vue";

export default {
  components: { Linkdin, LSection },
  name: "Footer",
  setup() {
    console.log("Footer.vue setup()");
    return {};
  },
};
</script>

<style scoped></style>
