import { createRouter, createWebHashHistory } from "vue-router";
import { state } from "../store/index.js";
import LandingView from "@/views/LandingView";
import assetRoutes from "@/router/routes/assetRoutes";
import accountRoutes from "@/router/routes/accountRoutes";
import playlistRoutes from "@/router/routes/playlistRoutes";
import { capitalize } from "@/lib/typeHelpers";
import config from "../../config.js";

const { appId, basePath, host, permissionThresholds } = config;
const getRoute = (routes, name) =>
  routes.reduce((match, route) => {
    if (!match) {
      if (route.name === name) match = route;
      else if (route.children) match = getRoute(route.children, name);
    }
    return match;
  }, null);

const routes = [
  {
    path: "/",
    name: "home",
    component: LandingView,
  },
  {
    path: "/organisations",
    name: "organisations",
    props: true,
    component: () => import("../views/Organisation/Organisations.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    props: true,
    component: () => import("../views/Contact/ContactView.vue"),
  },
  {
    path: "/qtEdu",
    name: "qtEdu",
    props: true,
    component: () => import("../views/QTEdu.vue"),
  },
  {
    path: "/qtEdu/resource/:title",
    name: "qtEduResource",
    props: true,
    component: () => import("../views/QTEduResource.vue"),
  },
  {
    path: "/news",
    name: "news",
    props: true,
    component: () => import("../views/News.vue"),
  },
  {
    path: "/analysis",
    name: "analysis",
    props: true,
    component: () => import("../views/analysis/Analysis.vue"),
  },
  {
    path: "/about",
    name: "about",
    props: true,
    component: () => import("../views/About/About.vue"),
    children: [
      {
        path: "us",
        name: "aboutUs",
        meta: { title: "About Us" },
        props: true,
        components: {
          default: () => import("../views/About/About.vue"),
          aboutTab: () => import("../views/About/tabs/AboutUs.vue"),
        },
      },
      {
        path: "medals",
        name: "aboutMedals",
        meta: { title: "About Medals" },
        props: true,
        components: {
          default: () => import("../views/About/About.vue"),
          aboutTab: () => import("../views/About/tabs/AboutMedals.vue"),
        },
      },
      {
        path: "principles",
        name: "aboutPrinciples",
        meta: { title: "About Principles" },
        props: true,
        components: {
          default: () => import("../views/About/About.vue"),
          aboutTab: () => import("../views/About/tabs/AboutPrinciples.vue"),
        },
      },
    ],
  },
  {
    path: "/education&training",
    name: "education&training",
    props: true,
    component: () =>
      import("../views/Education&training/Education&training.vue"),
    children: [
      {
        path: "europe-qt-community",
        name: "europeQTCommunity",
        meta: { title: "QTEdu Community" },
        props: true,
        components: {
          default: () =>
            import("../views/Education&training/Education&training.vue"),
          educationTrainingTabs: () =>
            import(`../views/Education&training/tabs/EuropeQTCommunity.vue`),
        },
      },
      {
        path: "resources-qt-community",
        name: "resourcesQTCommunity",
        meta: { title: "Community resources" },
        props: true,
        components: {
          default: () =>
            import("../views/Education&training/Education&training.vue"),
          educationTrainingTabs: () =>
            import(`../views/Education&training/tabs/ResourcesQTCommunity.vue`),
        },
      },
      {
        path: "competence-framework",
        name: "competenceFramework",
        meta: { title: "Competence framework" },
        props: true,
        components: {
          default: () =>
            import("../views/Education&training/Education&training.vue"),
          educationTrainingTabs: () =>
            import(`../views/Education&training/tabs/CompetenceFramework.vue`),
        },
      },
      {
        path: "quantum-masters",
        name: "quantumMasters",
        meta: { title: "Quantum Masters" },
        props: true,
        components: {
          default: () =>
            import("../views/Education&training/Education&training.vue"),
          educationTrainingTabs: () =>
            import(`../views/Education&training/tabs/QuantumMasters.vue`),
        },
      },
      {
        path: "ongoing-eu-projects",
        name: "ongoingEUProjects",
        meta: { title: "Ongoing EU projects" },
        props: true,
        components: {
          default: () =>
            import("../views/Education&training/Education&training.vue"),
          educationTrainingTabs: () =>
            import(`../views/Education&training/tabs/OngoingEUProjects.vue`),
        },
      },
    ],
  },
  {
    path: "/createnewsitem",
    name: "createnewsitem",
    props: true,
    component: () => import("../components/News/CreateNewsItem"),
  },
  {
    path: "/news/:assetId",
    name: "newsArticle",
    props: true,
    component: () => import("../views/NewsArticle.vue"),
  },
  {
    path: "/organisations/:organisationId",
    props: true,
    component: () => import("../views/Organisation/Organisation.vue"),
    children: [
      {
        path: "about",
        name: "organisationAbout",
        meta: { title: "About organisation" },
        props: true,
        components: {
          default: () => import("../views/Organisation/Organisation.vue"),
          organisationTab: () =>
            import("../views/Organisation/tabs/OrganisationAbout.vue"),
        },
      },
      {
        path: "members",
        name: "organisationMembers",
        meta: { title: "Organisation members" },

        props: true,
        components: {
          default: () => import("../views/Organisation/Organisation.vue"),
          organisationTab: () =>
            import("../views/Organisation/tabs/OrganisationMembers.vue"),
        },
      },
      {
        path: "accords",
        name: "organisationAccords",
        meta: { title: "Organisation accords" },
        props: true,
        components: {
          default: () => import("../views/Organisation/Organisation.vue"),
          organisationTab: () =>
            import("../views/Organisation/tabs/OrganisationAccords.vue"),
        },
      },
    ],
  },
  {
    path: "/organisations/:organisationId/accords/:assetId",
    name: "accordDisplay",
    meta: { title: "Display accord" },
    props: true,
    component: () => import("../views/Accords/AccordDisplay.vue"),
  },
  {
    path: "/registerOrganisation",
    name: "registerOrganisation",
    meta: { title: "Register Organisation" },
    props: true,
    component: () =>
      import("../components/Organisation/RegisterNewOrganisation.vue"),
  },
  {
    path: "/accords",
    name: "accords",
    props: true,
    component: () => import("../views/Accords/AccordsWithTabs.vue"),
    children: [
      {
        path: "about",
        name: "aboutAccords",
        meta: { title: "About Accords" },
        props: true,
        components: {
          default: () => import("../views/Accords/AccordsWithTabs.vue"),
          accordsTab: () => import("../views/Accords/tabs/AboutAccords.vue"),
        },
      },
      {
        path: "educational",
        name: "educationalAccords",
        meta: { title: "Educational Accords" },
        props: true,
        components: {
          default: () => import("../views/Accords/AccordsWithTabs.vue"),
          accordsTab: () =>
            import("../views/Accords/tabs/EducationalAccords.vue"),
        },
      },
      {
        path: "industrial",
        name: "industrialAccords",
        meta: { title: "Industrial Accords" },
        props: true,
        components: {
          default: () => import("../views/Accords/AccordsWithTabs.vue"),
          accordsTab: () =>
            import("../views/Accords/tabs/IndustrialAccords.vue"),
        },
      },
      {
        path: "submitAccord",
        name: "submitAccord",
        meta: { title: "Submit an accord" },
        props: true,
        components: {
          default: () => import("../views/Accords/AccordsWithTabs.vue"),
          accordsTab: () => import("../views/Accords/tabs/SubmitAccord.vue"),
        },
      },
    ],
  },
  {
    path: "/impact",
    props: true,
    component: () => import("../views/Impact/ImpactWithTabs.vue"),
    children: [
      {
        path: "",
        name: "impact",
        meta: { title: "Quantum Impact" },
        props: true,
        components: {
          default: () => import("../views/Impact/ImpactWithTabs.vue"),
          impactTab: () => import("../views/Impact/tabs/ImpactPage.vue"),
        },
      },
      {
        path: "projects",
        name: "projects",
        meta: { title: "Quantum Projects" },
        props: true,
        components: {
          default: () => import("../views/Impact/ImpactWithTabs.vue"),
          impactTab: () => import("../views/Impact/tabs/ProjectsPage.vue"),
        },
      },
    ],
  },
  {
    path: "/impact/communication-networking",
    name: "communication-networking",
    meta: { title: "Communication & Networking" },
    component: () =>
      import("../views/Impact/8Impacts/CommunicationNetworking.vue"),
  },
  {
    path: "/impact/environmental-sustainability",
    name: "environmental-sustainability",
    meta: { title: "Environmental Sustainability" },
    component: () =>
      import("../views/Impact/8Impacts/EnvironmentalSustainability.vue"),
  },
  {
    path: "/impact/finance-economics",
    name: "finance-economics",
    meta: { title: "Finance & Economics" },
    component: () => import("../views/Impact/8Impacts/FinanceEconomics.vue"),
  },
  {
    path: "/impact/health-medicine",
    name: "health-medicine",
    meta: { title: "Health & Medicine" },
    component: () => import("../views/Impact/8Impacts/HealthMedicine.vue"),
  },
  {
    path: "/impact/manufacturing-industry",
    name: "manufacturing-industry",
    meta: { title: "Manufacturing & Industry" },
    component: () =>
      import("../views/Impact/8Impacts/ManufacturingIndustry.vue"),
  },
  {
    path: "/impact/security-defence",
    name: "security-defence",
    meta: { title: "Security & Defence" },
    component: () => import("../views/Impact/8Impacts/SecurityDefence.vue"),
  },
  {
    path: "/impact/space-exploration-research",
    name: "space-exploration-research",
    meta: { title: "Space Exploration & Research" },
    component: () =>
      import("../views/Impact/8Impacts/SpaceExplorationResearch.vue"),
  },
  {
    path: "/impact/transport-infrastructure",
    name: "transport-infrastructure",
    meta: { title: "Transport & Infrastructure" },
    component: () =>
      import("../views/Impact/8Impacts/TransportInfrastructure.vue"),
  },

  {
    path: "/createaccords/:organisationId",
    name: "createaccords",
    meta: { title: "Create Accord" },
    props: true,
    component: () => import("../components/accords/CreateAccord"),
  },
  ...assetRoutes,
  ...accountRoutes,
  ...playlistRoutes,
  {
    // todo: test
    path: `/:backendFilePath(${appId}/.+)`,
    redirect(to) {
      const { backendFilepath } = to.params;
      return { path: `${host}/${backendFilepath}` };
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  base: basePath,
  routes,
});

router.beforeEach((to, from, next) => {
  let destination;

  document.title =
    capitalize(to.meta?.title ?? to.name ?? to.path) +
    " | European Quantum Readiness Center";
  window.scrollTo(0, 0);
  if (to.meta.requireLogin && !state.user)
    destination = getRoute(routes, "Landing");
  // if (to.meta.requireAssetAdmin && !asset.__permissions >= permissionThresholds.manageAsset) // todo: get asset from store by id <<<< standardize assetId param across components
  //   destination = getRoute(routes, "Landing");
  if (
    to.meta.requireAppAdmin &&
    !state.account?.role >= permissionThresholds.manageApp
  )
    destination = getRoute(routes, "Landing");
  if (
    to.meta.requireDevOp &&
    !state.account?.role >= permissionThresholds.devOp
  )
    destination = getRoute(routes, "Landing");
  if (destination?.path === to.path) next();
  else next(destination);
});
// router.afterEach(() => {
//   state.transitionName = ''
// })
// router.afterEach((to, from) => {
//   const getLevel = ({path}) => path.split('/levels/')?.[1]
//   const toLevel = getLevel(to)
//   const fromLevel = getLevel(from) ?? 0
//   to.meta.transitionName = toLevel < fromLevel ? 'ascend' : 'descend'
//   console.log(8, to.meta.transitionName, fromLevel, toLevel)
// })
export default router;
