<template>
  <div class="seeMoreButton flex flex-row justify-end">
    <div class="flex group-hover:animate-wiggle w-fit flex-row justify-between">
      <button class="w-auto rounded text-sm whitespace-nowrap">See More</button>

      <img
        src="/gradient_arrow.svg"
        class="w-5 ml-1 gradient-to-br from-pink-600 to-orange-500"
        alt="See more"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SeeMoreButton",
};
</script>

<style scoped></style>
