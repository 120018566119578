import store, {state} from "../store";
import {computed, ref} from "vue";
import {useAssetActions} from "@/composables/lib/useAssetActions";
import {getDocumentKey} from "@/composables/lib/getDocumentKey";
import {
    CREATE_ASSET,
    DELETE_ASSET,
    LIST_ASSETS,
    REGISTER_USER,
} from "@/store/operations";
import {isArray, isObject, isString} from "@/lib/getVariableType";
import config from "../../config";

const {appId, permissionThresholds} = config

export const useDocuments = (documentType, {refresh, parentDataType, parentRepoId, permissionHostDataType, permissionHostRepoId} = {}) => {
    const {dataType} = useAssetActions(documentType);
    const parentKey = getDocumentKey({appId, dataType: parentDataType, repoId: parentRepoId});
    const shouldLoadDocuments = computed(() => {
        let pass = false;

        const isLoaded = documents.value?.length;
        pass ||= !isLoaded || refresh;

        const isLoggedIn = state.user?.name;
        pass &&= isLoggedIn || dataType !== "UserAccount";

        return pass;
    });

    const documents = computed(() =>
        Object.values(state?.assets?.[dataType] ?? {})
            .filter(doc =>
                doc.isPrivate !== true ||
                [permissionThresholds.manageApp, permissionThresholds.manageAsset].includes(doc._permissions)
            )
    )

    const createAccount = (properties) =>
        store.dispatch(REGISTER_USER, properties);


    const builtInAttributes = [
        'id',
        'repoId',
        'dataType',
        'appId',
        'permissionHostDataType',
        'permissionHostRepoId',
        'parentDataType',
        'parentId',
        'slotCount',
        'documentSequence'
    ]
    const createDocument = (properties) => {
        const payload = {
            ...properties,
            dataType,
        }; // shed the reactivity
        if (dataType === "UserAccount") return createAccount(payload);

        if (permissionHostDataType && permissionHostRepoId) {
            payload.permissionHostDataType = permissionHostDataType;
            payload.permissionHostRepoId = permissionHostRepoId;
        }

        // move attributes to payload.attributes
        const attributes = Object.fromEntries(
            Object.entries(payload).reduce((entries, entry) => {
                const [key] = entry;
                if (!builtInAttributes.includes(key)) {
                    entries.push(entry);
                    delete payload[key];
                }
                return entries;
            }, [])
        );
        payload.attributes = attributes;
        return store.dispatch(CREATE_ASSET, payload);
    };
    const filteredDocuments = computed(() => {
        return Object.values(state.assets[dataType] ?? {}).filter(filterDocument);
    });

    const loadDocuments = (refresh, repoId) => {
        if (!filteredDocuments.value?.length || refresh) {
            const options = {dataType};
            if (repoId) options.repoId = repoId;
            store.dispatch(LIST_ASSETS, options);
        }
    };
    const deleteDocument = (id) => store.dispatch(DELETE_ASSET, {dataType, id});

    const filter = ref({});

    function filterDocument(doc) {
        // eslint disable-next-line no-unused-vars
        const applyAttributeFilter = (value, filterValue) => {
            if (isString(value)) {
                value = value.toLowerCase();
            }
            if (isString(filterValue)) {
                filterValue = filterValue.toLowerCase();
            }
            if (isArray(filterValue)) {
                filterValue = filterValue.map((item) => {
                    if (isString(item)) {
                        item.toLowerCase();
                    }
                    return item;
                });
            }
            return (
                value === filterValue ||
                (isString(value) && value?.includes(filterValue)) || // partial match of contained item
                (isArray(value) &&
                    [...value].find((item) => applyAttributeFilter(item, filterValue)))
            );
        };

        const noFilter =
            !isObject(filter.value) || !Object.keys(filter.value).length;

        if (noFilter) return true

        const params = Object.keys(filter.value);

        let pass = params.reduce((pass, param) => {
            return pass ||
                applyAttributeFilter(doc[param], filter.value?.[param]);
        }, false);
        // is not private or is private and has permission to view
        // pass = pass && !doc.isPrivate || [permissionThresholds.manageApp, permissionThresholds.manageAsset].includes(doc._permissions)

        return pass;
    }

    if (shouldLoadDocuments.value) loadDocuments(true);

    return {
        createDocument,
        deleteDocument,
        documents,
        filter,
        filteredDocuments,
        loadDocuments,
        parentKey,
    };
};
