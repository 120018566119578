import {capitalize} from "@/lib/typeHelpers";
import {initAssetStore} from "@/composables/lib/initAssetStore";
import {state} from "@/store";

export const useAssetActions = dataType => {

  dataType = capitalize(dataType)
  initAssetStore(state, dataType)

  return {
    dataType,
    typeAlias: dataType.toLowerCase()
  }
}
