<script>
import LSection from "@/components/layout/LSection.vue";
import CardsNews from "@/components/News/CardsNews.vue";

export default {
  name: "QuantumNewsLandingPage",
  components: {
    LSection,
    CardsNews,
  },
};
</script>

<template>
  <LSection class="k1:py-24 py-12">
    <template #body>
      <div class="flex items-center mb-8">
        <h3 class="heading2 gradientText w-full h-full mt-0 !mb-0">
          Latest Quantum News
        </h3>
        <button
          class="h-fit button main flex-shrink-0"
          @click="() => $router.push(`/news`)"
        >
          See all news
        </button>
      </div>
      <CardsNews item-count="3"></CardsNews>
    </template>
  </LSection>
</template>

<style scoped></style>
