<template>
  <!-- Invite member template, should have a choice between member and admin-->
  <div
      class="manageAssetMembers form p-6 w-auto"
  >
    <h1 class="heading2">Manage Members</h1>
    <p>
      Admins can manage users (including you) here.
    </p>
    <p>
      Editors can create, edit, and delete accords in this organisation.
    </p>
    <form @submit.prevent="onInviteMember">
      <div class="flex w-max align-middle flex-row space-x-4">
        <div class="flex justify-between p-4 items-center gap-2 flex-row">
          <div class="flex flex-row space-x-4">
            <TextInput
                class="w-96"
                type="email"
                placeholder="Email"
                v-model="newMember.email"
                @change="value => (newMember.email = value)"
            />
            <div
                class="flex group/principle flex-col"
                @click="onRoleChange(true)"
            >
              <input
                  type="radio"
                  id="admin"
                  name="role"
                  :checked="newMember.role === 'manageAsset'"
              />
              <label for="admin">Admin</label>
              <div class="hidden group-hover/principle:block popUp">
                <p>
                  Admins can edit and delete the organisation, invite and expel members
                </p>
              </div>
            </div>
            <div
                class="flex group/principle flex-col"
                @click="onRoleChange(false)"
            >
              <input
                  type="radio"
                  id="member"
                  name="role"
                  :checked="newMember.role === 'developAsset'"
              />
              <label for="member">Editor</label>
              <div class="hidden group-hover/principle:block popUp">
                <p>Editors can edit organisation information and accords</p>
              </div>
            </div>
            <Button
                class=""
                type="submit"
                :is-primary="true"
                @click="onInviteMember"
            >
              <template #center>Invite</template>
            </Button>
          </div>
        </div>
      </div>
      <hr/>
    </form>
    <AssetMemberListItem
        v-for="(member, index) in memberList"
        :key="index"
        :_user="member"
        @change="value => onMemberChange(value, member)"
    />

    <div class="flex mt-4 flex-row justify-center space-x-4">
      <Button
          class="button secondary"
          :is-primary="false"
          @click="$emit('close')"
      >
        <template #center>Cancel</template>
      </Button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/forms/TextInput.vue";
import Button from "@/components/forms/Button.vue";
import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import AssetMemberListItem from "@/components/admin/AssetMemberListItem.vue";
import {DEMOTE_ASSET_USER, EXPEL_ASSET_USER, INVITE_USER, PROMOTE_ASSET_USER} from "@/store/operations";
import {useDocument} from "@/composables/useDocument";
import config from "../../../config";
import {useAssetUsers} from "@/composables/useAssetUsers";
import {useUserPermissions} from "@/composables/useUserPermissions";

const {appId} = config;
const getDefaultMember = () =>
    new Object({
      organisationId: "",
      email: "",
      role: 'developAsset',
      dataType: 'Accord',
      repoId: `${appId}-Accord`
    });
export default {
  name: "ManageAssetMembers",
  emits: ["done", "close"],
  components: {
    AssetMemberListItem,
    Button,
    TextInput,
  },
  setup(props, {emit}) {
    const dataType = 'Organisation'
    const route = useRoute();
    const {user} = useUserPermissions()
    const universityRepoId = route.params.organisationId;
    const error = ref(null);

    const newMember = reactive({...getDefaultMember()});

    const organisation = useDocument(dataType, universityRepoId);
    const {documents, loadDocuments} = useAssetUsers(dataType, universityRepoId, {refresh: true})

    const memberList = computed(() => {
      // console.log(223, documents.value.map(d => `${d.email}: ${d._userPermission}`))
      return documents.value.filter(
          person => person._userPermission > 1 &&
              person._userPermission <= 5 &&
              person.repoId !== user.value.repoId
      )
    })
    const store = useStore();

    const onRoleChange = (value) => {
      if (value) Object.assign(newMember, {
        role: 'manageAsset',
        dataType: dataType,
        universityRepoId: organisation.repoId
      })
      else Object.assign(newMember, {
        role: 'maintainAsset',
        dataType: 'Accord',
        universityRepoId: `${appId}-Accord`
      })
    };
    const onInviteMember = () => {
      try {
        store.dispatch(
            INVITE_USER,
            {
              ...newMember,
            }
        )
            .then(() => {
              store.state.UI.message = "Member invited!"
              loadDocuments(true);
              emit('done')
            })
      } catch (err) {
        error.value = err;
      }
      newMember.value = reactive(getDefaultMember());
    };
    const onMemberChange = (value, member) => {
      if (value === 'manageAsset') {
        onPromoteMember(member.repoId)
      } else if (value) {
        onDemoteMember(member.repoId)
      } else {
        onExpelMember(member.repoId)
      }
    }
    const onDemoteMember = userRepoId => {
      try {
        store.dispatch(DEMOTE_ASSET_USER, {
          dataType,
          repoId: universityRepoId,
          userRepoId
        })
            .then(() => {
              store.state.UI.message = "Member demoted!"
              loadDocuments(true);
              emit('done')
            })
      } catch (err) {
        error.value = err;
      }
    };
    const onExpelMember = userRepoId => {
      try {
        store.dispatch(EXPEL_ASSET_USER, {
          dataType,
          repoId: universityRepoId,
          userRepoId
        })
            .then(() => {
              store.state.UI.message = "Member expelled!"
              loadDocuments(true);
              emit('done')
            })
      } catch (err) {
        error.value = err;
      }
    };
    const onPromoteMember = userRepoId => {
      try {
        store.dispatch(PROMOTE_ASSET_USER, {
          dataType,
          repoId: universityRepoId,
          userRepoId
        })
            .then(() => {
              store.state.UI.message = "Member promoted!"
              loadDocuments(true);
              emit('done')
            })
      } catch (err) {
        error.value = err;
      }
    };

    return {
      error,
      memberList,
      newMember,
      onInviteMember,
      onEmailChange: value => newMember.email = value,
      onMemberChange,
      // onNameChange: value => newMember.name = value,
      onRoleChange,
    };
  },
};
</script>

<style scoped></style>
