export default {
  alt: 'an image',
  blur: 0,
  brightness: 1,
  caption: 'An abstract quantum-ready image',
  contrast: 1,
  cover: true,
  grayscale: 0,
  height: 300,
  hue: 0,
  invert: 0,
  offset: ['0', '0'],
  radius: [0, 0, 0, 0],
  saturate: 1,
  sepia: 0,
  shadow: [0, 0, 1, 'black'],
  showCaption: false,
  size: 80,
  url: 'https://images.unsplash.com/photo-1609705025038-60908171cf5e?ixlib=rb-4.0.3&ixid=MnwxM[…]90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80',
  zoom: 1,
}
