import { reactive } from "@vue/reactivity";
const errors = reactive({});
export const useFieldValidation = () => {
  // console.log(value);
  const validate = (test) => !!test;

  // userAccount.name = input;
  // error.value = userAccount.name === "" ? "The Input field is required" : "";
  // console.log(userAccount.name, error.value);
  return { errors, validate };
};
