import { createStore } from "vuex";
import actions from "./actions/index.js";
import mutations from "./mutations.js";
import { reactive } from "vue";

export const state = reactive({
  assets: {},
  status: {},
  acknowledgedErrors: {},
  documentFilter: null,
  queryStates: {},
  UI: {
  },
  appId: 'eqrc',
});

export default createStore({
  state,
  mutations,
  actions,
});

