import {isArray, isString} from "../typeHelpers/getVariableType";

const any = '*'
const axes = ['x', 'y']
export const edges = [
  'top',
  'right',
  'bottom',
  'left',
]
export const vertices = [
  'top left',
  'top right',
  'bottom left',
  'bottom right',
]
const all = [
  '*',
  ...axes,
  ...edges,
  ...vertices,
]
const normalizeToArrayOfStrings = param => {
  if (isString(param)) param = param.trim()
    .split(',')
    .map(alias =>
      alias.toLowerCase().trim()
    )

  if (!isArray(param)) param = [...param]
  return param
}

const parseFilter = (filter, {defaultValue}) => {
  filter = filter ?? defaultValue
  if (!filter) return []

  filter = normalizeToArrayOfStrings(filter)
  if (filter.includes(any)) return all

  const list = new Set([])

  if (filter.includes('axes')) {
    for (let name of axes) list.add(name)
  }
  if (filter.includes('edges')) {
    for (let name of edges) list.add(name)
  }
  if (filter.includes('vertices')) {
    for (let name of vertices) list.add(name)
  }

  for (let name of all) {
    if (filter.includes(name)) list.add(name)
  }
  return [...list]
}
const limitToEdges = names => names.filter(name => edges.includes(name))
const limitToVertices = names => names.filter(name => vertices.includes(name))

export const validateSelector = (selector, filter = any) => {
  filter = parseFilter(filter, {defaultValue: any})
  return !selector ||
    (
      isString(selector) &&
      filter.includes(selector)
    ) ||
    (
      isArray(selector) &&
      selector.reduce((pass, s) => pass && filter.includes(s), true)
    )
}
export const validateAxisSelector = (selector, filter = axes) => {
  filter = parseFilter(filter, {defaultValue: axes})
  return validateSelector(selector, filter)
}
export const validateEdgeSelector = (selector, filter = edges) => {
  filter = parseFilter(filter, {defaultValue: edges})
  limitToEdges(filter)
  return validateSelector(selector, filter)
}
export const validateVertexSelector = (selector, filter = edges.concat(vertices)) => {
  filter = parseFilter(filter, {defaultValue: edges.concat(vertices)})
  limitToVertices(filter)
  return validateSelector(selector, filter)
}
export const useBoundarySelectorValidator = filter => value => {
  return validateSelector(value, filter)
}
