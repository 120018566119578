<template>
  <section
    class="URL1 bg-slate-100/25 rounded-md text-gray-700 p-4 mb-10 shadow"
    v-show="url"
  >
    <div class="URL-1 mb-4 relative">
      <iframe
        ref="iframe"
        :src="url"
        frameborder="0"
        class="object-cover w-full aspect-video rounded-md"
      ></iframe>
    </div>
    <!-- Detail -->
    <div class="font-header">
      <div class="flex flex-col items-center justify-between">
        <div class="flex flex-col justify-between mx-auto w-full">
          <div class="flex">
            <span class="font-semibold mr-1">Author :</span>
            <span>{{ author }}</span>
          </div>
          <div class="flex">
            <span class="font-semibold mr-1">Language :</span>
            <span>{{ language }}</span>
          </div>
        </div>
        <div class="flex flex-col justify-between mx-auto w-full">
          <div class="flex">
            <span class="font-semibold mr-1">Duration :</span>
            <span>{{ duration }}</span>
          </div>
        </div>
        <div class="flex flex-col justify-center w-full">
          <div class="flex items-center">
            <EasyCircle v-if="level === 'Easy'" />
            <MediumCircle v-if="level === 'Medium'" />
            <AdvancedCircle v-if="level === 'Advanced'" />
            <MixedCircle v-if="level === 'Mixed'" />
            <NotLevel v-if="!level" />
            <span class="font-semibold mr-1">Level :</span>
            <span> {{ level }}</span>
          </div>
        </div>
        <div class="flex w-full">
          <a
            class="flex duration-300 ease-in-out cursor-pointer gradientText hoverUnderline"
            href="https://qtedu.eu/european-competence-framework-quantum-technologies"
            target="_blank"
          >
            <span class="font-bold mr-1">Competence-Framework:</span>
            <span>
              {{ framework }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AdvancedCircle from "./icons/AdvancedCircle.vue";
import EasyCircle from "./icons/EasyCircle.vue";
import MediumCircle from "./icons/MediumCircle.vue";
import MixedCircle from "./icons/MixedCircle.vue";
import NotLevel from "./icons/NotLevel.vue";

export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    author: {
      type: String,
      default: "",
    },
    language: {
      type: String,
      default: "",
    },
    duration: {
      type: String,
      default: "",
    },
    level: {
      type: String,
      default: "",
    },
    framework: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
  components: {
    EasyCircle,
    MediumCircle,
    AdvancedCircle,
    MixedCircle,
    NotLevel,
  },
};
</script>

<style lang="scss" scoped></style>
