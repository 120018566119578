import store, {state} from "../store";
import {capitalize, computed, ref} from "vue";
import {
    LIST_ASSET_MEMBERS,
} from "@/store/operations";
import {isArray, isNumber, isObject, isString} from "@/lib/getVariableType";

export const useAssetUsers = (dataType, repoId, {refresh} = {}) => {
    if (!repoId) throw new Error("repoId is required");
    dataType = capitalize(dataType)

    const shouldLoadDocuments = computed(() => {
        let pass = false;

        const isLoaded = documents.value?.length;
        pass ||= !isLoaded || refresh;

        return pass;
    });
    const documents = computed(() => Object.values(state.assets?.User ?? {}))
    const filter = ref({});

    const normalizeValue = value => {
        if (isNumber(value)) value = value.toString()
        if (isString(value)) value = value.toLowerCase()
        if (isArray(value)) value.forEach((item, i) => value[i] = normalizeValue(item))
        return value
    }

    function filterDocument(doc) {
        // eslint-disable-next-line no-unused-vars
        const applyAttributeFilter = (value, filterValue) => {
            let pass = true;
            if (filterValue !== undefined && value !== undefined) {
                const matchAnyOfValue = (value, filterValue) => value.includes(filterValue)
                const findInFilterValue = (value, filterValue) => filterValue.includes(value)
                value = normalizeValue(value)
                filterValue = normalizeValue(filterValue)
                pass =
                    value === filterValue ||
                    matchAnyOfValue(value, filterValue) ||
                    findInFilterValue(value, filterValue)
            }
            return pass
        };
        const noFilter = !isObject(filter.value) || !Object.keys(filter.value).length;

        let pass = noFilter;
        if (!pass) {
            const params = Object.keys(filter.value);
            pass = params.reduce((pass, param) => {
                return pass || applyAttributeFilter(doc[param], filter.value?.[param]);
            }, pass);
        }

        return pass;
    }

    const filteredDocuments = computed(() =>  Object.values(state.assets?.User ?? {}).filter(filterDocument))

    const loadDocuments = refresh => {
        if (!filteredDocuments.value?.length || refresh) {
            store.dispatch(LIST_ASSET_MEMBERS, {
                dataType,
                repoId,
            })
        }
    };

    if (shouldLoadDocuments.value) loadDocuments(refresh);

    return {
        documents,
        filter,
        filteredDocuments,
        loadDocuments,
    };
};
