<template>
  <div class="cardsNews">
    <div class="w-full flex flex-wrap gap-4 k1:justify-start justify-center">
      <NewsItem
        class="max-w-[20rem]"
        v-for="document in list"
        :key="document.id"
        :asset-id="document.repoId"
        @click="$router.push('/news/' + document.repoId)"
      ></NewsItem>
    </div>
  </div>
</template>

<script>
import NewsItem from "./NewsItem.vue";
import { useDocuments } from "@/composables/useDocuments";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "CardsNews",
  components: {
    NewsItem,
  },
  props: {
    itemCount: {
      type: Number,
      default: null, // Default to null to indicate "show all"
    },
  },
  setup(props) {
    const { documents } = useDocuments("News");
    const list = computed(() => {
      const sortedDocuments = [...(documents.value ?? [])].sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      );
      if (props.itemCount !== null) {
        return sortedDocuments.slice(0, props.itemCount);
      }
      return sortedDocuments; // Show all if itemCount is null
    });
    return {
      list,
    };
  },
});
</script>

<style scoped></style>
