export const LIST_ASSETS = 'LIST_ASSETS'
export const LIST_PRIVATE_ASSETS = 'LIST_PRIVATE_ASSETS'
export const GET_CLIENT_ID = 'GET_CLIENT_ID'
export const GET_ASSET = 'GET_ASSET'
export const CREATE_ASSET = 'CREATE_ASSET'
export const PUT_ASSET = 'PUT_ASSET'
export const PUT_PRIVATE_ASSET = 'PUT_PRIVATE_ASSET'
export const PUBLISH_ASSET = 'PUBLISH_ASSET'
export const RETRACT_ASSET = 'RETRACT_ASSET'
export const PUT_ASSET_ATTRIBUTE = 'PUT_ASSET_ATTRIBUTE'
export const DELETE_ASSET = 'DELETE_ASSET'

export const LIST_USERS = 'LIST_USERS'
export const LIST_ASSET_MEMBERS = 'LIST_ASSET_USERS'
export const DEMOTE_ASSET_USER = 'DEMOTE_ASSET_USER'
export const PROMOTE_ASSET_USER = 'PROMOTE_ASSET_USER'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const EXPEL_ASSET_USER = 'EXPEL_ASSET_USER'
export const GET_USER = 'GET_USER'
export const REGISTER_USER = 'REGISTER_USER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const RESUME_SESSION = 'RESUME_SESSION'
export const REQUEST_FORGET_USER = 'REQUEST_FORGET_USER'
export const INVITE_USER = 'INVITE_USER'

export const LIST_APP_USERS = 'LIST_APP_USERS'
export const INVITE_APP_USER_AS = 'INVITE_APP_USER_AS'
export const BAN_USER = 'BAN_USER'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const REQUEST_CHANGE_EMAIL = 'REQUEST_CHANGE_EMAIL'

// token confirmation
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION'
export const CONFIRM_RESET_PASSPHRASE = 'CONFIRM_RESET_PASSPHRASE'
export const CONFIRM_CHANGE_EMAIL = 'CONFIRM_CHANGE_EMAIL'
export const ACTIVATE_NEW_EMAIL = 'ACTIVATE_NEW_EMAIL'
export const CONFIRM_DOWNLOAD_MY_DATA = 'CONFIRM_DOWNLOAD_MY_DATA'
export const CONFIRM_FORGET_ABOUT_ME = 'CONFIRM_FORGET_ABOUT_ME'

// INTERNAL MUTATIONS
export const _PUT_STATUS = '_PUT_STATUS'
export const _RESET_ASSETS = '_RESET_ASSETS'