const inProduction = process.env?.NODE_ENV !== 'development'
const USING_TLS = inProduction

const productionDomain = 'quantumready.eu'
const hostName = inProduction ? productionDomain : 'localhost'
const emailSender = 'noReply@scienceAtHome.org'
const PORT = process.env.PORT || 3039

const PROTOCOL = USING_TLS ? 'https:' : 'http:'
const host = `${PROTOCOL}//${hostName}${inProduction ? '' : `:${PORT}`}`

const settings = {
  productionDomain,
  hostName,
  host,
  apiPath: '/api/v1',
  appId: 'eqrc',
  basePath: '',
  protocol: PROTOCOL,

  appTitle: 'European Quantum Readiness Center',
  appPort: PORT,
  debugLevels: false,
  PRODUCTION_ORIGIN_PATTERN: '^.*$',
  useJSONDataStore: false,

  permissionThresholds: {
    'readAsset': 1,
    'maintainAsset': 2,
    'developAsset': 3,
    'manageAsset': 5,
    'readDataType': 41,
    'maintainDataType': 42,
    'developDataType': 43,
    'manageDataType': 45,
    'readApp': 71,
    'maintainApp': 72,
    'developApp': 73,
    'manageApp': 75,
    'devOp': 100,
  },
  roleNames: {
    maintainAsset: 'Asset Editor',
    developAsset: 'Asset Developer',
    manageAsset: 'Asset Manager',
    readApp: 'Member',
    manageApp: 'Manager',
    devOp: 'Developer'
  },
  emailSender,
  inProduction,
  USING_TLS,
}
module.exports = settings // legacy JS for server import
