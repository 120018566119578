export const getCSSFilterString = ({
  blur,
  brightness,
  contrast,
  grayscale,
  hue,
  invert,
  saturate,
  sepia
}) => {
  let filter = ''
  const filters = []
  if (blur > 0)
    filters.push(`blur(${blur}px)`)
  if (brightness !== undefined && brightness != 1)
    filters.push(`brightness(${brightness})`)
  if (contrast !== undefined && contrast != 1)
    filters.push(`contrast(${contrast})`)
  if (grayscale > 0)
    filters.push(`grayscale(${grayscale}%)`)
  if (hue > 0)
    filters.push(`hue-rotate(${hue}deg)`)
  if (invert > 0)
    filters.push(`invert(${invert}%)`)
  if (saturate !== undefined && saturate != 1)
    filters.push(`saturate(${saturate})`)
  if (sepia > 0)
    filters.push(`sepia(${sepia}%)`)
  if (filters.length) filter = filters.join(' ')

  return filter
}
