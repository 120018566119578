import PlayList from "@/views/newPlaylist/PlayList.vue";
import PlayListDetail from "@/views/newPlaylist/PlayListDetail.vue";

export default [
    {
        path: '/playlist',
        name: "playlist",
        component: PlayList,
        children: [
            {
                path: 'page/:id',
                name: "playlistDetail",
                props: true,
                component: PlayListDetail
            }
        ]
    },
    
]