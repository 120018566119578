<template>
  <div class="newsItem group ">
    <LImageTitleBodyCard :has-action="true" class="border-2">
      <template #image>
        <Image :url="news.imageUrl || '/eqrc/placeholder.jpg'" :height="200"></Image>
      </template>

      <template #title>
        <div style="
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          " class="break-normal ">{{ news.title }}
        </div>
      </template>

      <template #body>
        <div class="text-xs text-gray-500">
          <span> Added {{ relativeDate }} </span>
        </div>
        <p
            style="
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ news.description }}
        </p>
      </template>
    </LImageTitleBodyCard>
  </div>
</template>

<script>
import {useDocument} from "@/composables/useDocument";
import LImageTitleBodyCard from "@/components/layout/LImageTitleBodyCard";
import Image from "@/components/image/Image";
import {inject} from "vue";
import {computed} from "vue";

export default {
  name: "NewsItem",
  components: {
    Image,
    LImageTitleBodyCard,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dayJS = inject("DAYJS");
    const {news, loadNews} = useDocument("News", props.assetId);

    const relativeDate = computed(() => {
          return dayJS(news.value.date).fromNow();
        }
    );

      loadNews()

    return {
      news,
      relativeDate,
    };
  },
};
</script>
<style></style>
