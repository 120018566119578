<template>
  <!-- Invite member template, should have a choice between member and admin-->
    <div
            class="manageAppUsers form p-6 w-auto"
    >
        <h1 class="heading2">Manage Application Users</h1>

        <form @submit.prevent="onInviteUser">
            <div class="flex w-max align-middle flex-row space-x-4">
                <div class="flex justify-between p-4 items-center gap-2 flex-row">
                    <div class="flex flex-row space-x-4">
                        <TextInput
                                class="w-96"
                                type="email"
                                placeholder="Email"
                                v-model="newUser.email"
                                @change="onEmailChange"
                        />
                        <Button
                                class=""
                                type="submit"
                                :is-primary="true"
                                @click="onInviteUser"
                        >
                            <template #center>Invite</template>
                        </Button>
                    </div>
                </div>
            </div>
            <hr/>
        </form>
        <AppUserListItem
                v-for="(document, index) in filteredDocuments"
                :key="index"
                :_user="document"
                @change="onSetUserRole"
                @ban="onBanUser"
        />

        <div class="flex mt-4 flex-row justify-center space-x-4">
            <Button
                    class="button secondary"
                    :is-primary="false"
                    @click="$emit('close')"
            >
                <template #center>Cancel</template>
            </Button>
        </div>
    </div>
</template>

<script>
import TextInput from "@/components/forms/TextInput.vue";
import Button from "@/components/forms/Button.vue";
import {reactive, ref} from "vue";
import {useStore} from "vuex";
import {BAN_USER, INVITE_APP_USER_AS, SET_USER_ROLE} from "@/store/operations";
import config from "../../../config";
import {useAssetUsers} from "@/composables/useAssetUsers";
import AppUserListItem from "@/components/admin/AppUserListItem.vue";

const {appId} = config;

const assetTypes = ['News', 'Organisation']
const getDefaultUser = () =>
    new Object({
        appId,
        email: "",
        role: 'readApp',
    });

export default {
    name: "ManageAppUsers",
    emits: ["done", "close"],
    components: {
        AppUserListItem,
        Button,
        TextInput,
    },
    setup(props, {emit}) {
        const store = useStore();
        const dataType = 'App'
        const error = ref(null);

        const newUser = reactive(getDefaultUser());

        const {filteredDocuments, loadDocuments} = useAssetUsers(dataType, appId, {refresh: true})

        const onInviteUser = () => {
            try {
                store.dispatch(INVITE_APP_USER_AS, {...newUser})
                    .then(() => {
                        store.state.UI.message = "User invited!"
                        loadDocuments(true);
                        emit('done')
                    })
            } catch (err) {
                error.value = err;
            }
            newUser.value = reactive(getDefaultUser());
        }
        const onBanUser = userRepoId => {
            try {
                store.dispatch(BAN_USER, {
                    dataType,
                    repoId: appId,
                    userRepoId
                })
                    .then(() => {
                        store.state.UI.message = "Member expelled!"
                        loadDocuments(true);
                        emit('done')
                    })
            } catch (err) {
                error.value = err;
            }
        }
        const onSetUserRole = (userRepoId, role) => {
            try {
                store.dispatch(SET_USER_ROLE, {
                    appId,
                    userRepoId,
                    role
                })
                    .then(() => {
                        loadDocuments(true);
                        emit('done')
                    })
            } catch (err) {
                error.value = err;
            }
        }

        return {
            assetTypes,
            error,
            filteredDocuments,
            newUser,
            onBanUser,
            onEmailChange: value => newUser.email = value,
            onInviteUser,
            onRoleChange: value => newUser.role = value,
            onSetUserRole,
        };
    },
};
</script>

<style scoped></style>
