<template>
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--twemoji mr-1"
    preserveAspectRatio="xMidYMid meet"
  >
    <circle fill="#FF6B6B" cx="18" cy="18" r="18"></circle>
  </svg>
</template>
<script setup></script>
