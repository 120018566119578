<template>
  <div class="landing -mt-32">
    <Header />
    <EQRCHomePageSection />
    <SectionImageAndText />
    <!--    <ThePrinciples/>-->
    <!--    <HowDoesItWork />-->
    <AnalysisSection />
    <ResourcesSection />
    <QuantumNewsLandingPage />
  </div>
</template>

<script>
import Header from "@/components/Header";
import { state } from "@/store";
import { computed } from "vue";
import SectionImageAndText from "@/components/HomePageSections/SectionImageAndText.vue";
import QuantumNewsLandingPage from "@/components/HomePageSections/QuantumNewsLandingPage.vue";
import EQRCHomePageSection from "@/components/HomePageSections/EQRCHomePageSection.vue";
import AnalysisSection from "@/components/HomePageSections/AnalysisSection.vue";
import ResourcesSection from "@/components/HomePageSections/ResourcesSection.vue";
export default {
  name: "LandingView",
  components: {
    AnalysisSection,
    QuantumNewsLandingPage,
    EQRCHomePageSection,
    ResourcesSection,
    Header,
    SectionImageAndText,
  },
  setup() {
    const clientId = computed(() => state.clientId);
    return {
      clientId,
      state,
    };
  },
};
</script>

<style scoped></style>
