<template>
  <!--  <div class="k1:p-4 p-2 shadow-md leading-none bg-gray-50">-->
  <!--    <em class="k1:text-xl text-sm !text-justify">-->
  <!--      Embark on a quantum exploration with the Quantum Playlist, where curated-->
  <!--      videos, handpicked by experts, offer a seamless journey through the realms-->
  <!--      of quantum knowledge.-->
  <!--    </em>-->
  <!--  </div>-->
  <div
    class="w-full lg:w-fit px-2 p-1 items-center rounded-tl-3xl rounded-br-3xl flex flex-col text-gray-700"
  >
    <!--    <h1 class="">Video levels according to their difficulty</h1>-->
    <div class="flex flex-wrap items-start k1:gap-3 gap-3 text-sm">
      <p class="flex items-center justify-center font-medium">
        <EasyCircle />
        Easy
      </p>
      <p class="flex items-center justify-center font-medium">
        <MediumCircle />
        Medium
      </p>
      <p class="flex items-center justify-center font-medium">
        <AdvancedCircle />
        Advanced
      </p>
      <p class="flex items-center justify-center font-medium">
        <MixedCircle />
        Mixed
      </p>
    </div>
  </div>
</template>

<script>
import AdvancedCircle from "@/views/newPlaylist/icons/AdvancedCircle.vue";
import EasyCircle from "@/views/newPlaylist/icons/EasyCircle.vue";
import MediumCircle from "@/views/newPlaylist/icons/MediumCircle.vue";
import MixedCircle from "@/views/newPlaylist/icons/MixedCircle.vue";

export default {
  components: { EasyCircle, MediumCircle, AdvancedCircle, MixedCircle },
};
</script>

<style lang="scss" scoped></style>
