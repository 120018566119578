<script>
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "EQRCHomePageSection",
  components: {
    LSection,
  },
};
</script>

<template>
  <LSection>
    <template #body>
      <div class="w-full my-24">
        <div
          class="flex flex-col rounded k1:flex-row items-center h-full gap-4 k1:gap-10"
        >
          <img
            src="/Quantum2.png"
            alt="Img of quantum technology"
            class="w-full object-cover k1:h-[400px] h-[300px] rounded-2xl"
          />
          <div
            class="w-full h-full flex-grow group flex flex-col justify-between"
          >
            <p
              style="
                background: -webkit-linear-gradient(left, #db2777, #f97316);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              "
              class="heading2 k1:!text-5xl text-2xl"
            >
              The European Quantum Readiness Center
            </p>
            <div class="mt-0 k1:text-xl text-base leading-8">
              The European Quantum Readiness Center (EQRC) is dedicated to
              equipping companies and universities across Europe for the quantum
              revolution, ensuring they are at the forefront of this
              transformative technology.

              <hr class="gradientLine" />
              <button
                class="mt-4 button main"
                @click="() => $router.push(`/about/us`)"
              >
                Learn more
                <!--              Read more about EQRC-->
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LSection>
</template>

<style scoped></style>
