<template>
  <LSection>
    <template #body>
      <nav
        :key="showMenu"
        class="menuBar pt-6 h-20 text-gray-100 font-light text-sm dark:bg-gray-900"
      >
        <div
          class="flex justify-between mt-3 h6:mt-0 relative items-center z-10"
        >
          <a href="/" class="flex">
            <img src="../../public/logo.svg" class="h-10 h6:mt-0 h6:h-16" />
          </a>
          <div
            class="hidden k1:visible k1:flex k1:gap-4 absolute left-1/2 -translate-x-1/2"
          >
            <router-link
              v-for="tab in tabs"
              :key="tab.name"
              :to="tab.path"
              :class="{
                'underline decoration-[0.15rem] motion-safe:transition-all motion-safe:duration-200 hover:decoration-[0.25rem]':
                  $route.path === tab.path ||
                  $route.path.startsWith(tab.rootPath),
              }"
            >
              {{ tab.name }}
            </router-link>
          </div>

          <div class="k1:ml-8 gap-2 items-center flex k1:gap-4">
            <a href="https://qt.eu" target="_blank" rel="noopener noreferrer">
              <img
                src="../../public/SVG/logo-qt-contrast.svg"
                class="h-8 h6:mt-0 h6:h-12 h6:flex hidden"
              />
            </a>
            <!-- <button
                          @click="$router.push({ query: { modal: 'sign up' } })"
                          class="bg-gradient-to-br from-pink-600 to-orange-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Sign up
                        </button> -->
            <!--            <button-->
            <!--              v-if="!isLoggedIn"-->
            <!--              class="button main whitespace-nowrap"-->
            <!--              style="-->
            <!--                background-image: linear-gradient(to right, #db2777, #f97316);-->
            <!--              "-->
            <!--              @click="$router.push({ path: '/', query: { modal: 'login' } })"-->
            <!--            >-->
            <!--              Log In-->
            <!--            </button>-->
            <!--            <div v-else-if="account">-->
            <!--              <div class="flex text-xs gap-2 items-center">-->
            <!--                <div>Welcome {{ splitName }}</div>-->

            <!--                <button-->
            <!--                  class="hidden h6:block button main"-->
            <!--                  style="-->
            <!--                    background-image: linear-gradient(-->
            <!--                      to right,-->
            <!--                      #db2777,-->
            <!--                      #f97316-->
            <!--                    );-->
            <!--                  "-->
            <!--                  @click="onLogout"-->
            <!--                >-->
            <!--                  Log Out-->
            <!--                </button>-->
            <!--                &lt;!&ndash;                        <div class="k1:block hidden" v-if="user?.__isAppAdmin">&ndash;&gt;-->
            <!--                &lt;!&ndash;                            <button&ndash;&gt;-->
            <!--                &lt;!&ndash;                                    class="button main"&ndash;&gt;-->
            <!--                &lt;!&ndash;                                    style="background-image: linear-gradient(to right, #db2777, #f97316); "&ndash;&gt;-->
            <!--                &lt;!&ndash;                                    @click="$router.push({query: {modal: 'ManageAppUsers'}})"&ndash;&gt;-->
            <!--                &lt;!&ndash;                            >&ndash;&gt;-->
            <!--                &lt;!&ndash;                                Manage Users&ndash;&gt;-->
            <!--                &lt;!&ndash;                            </button>&ndash;&gt;-->
            <!--                &lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--              </div>-->
            <!--            </div>-->
            <burgerMenu @toggleMenu="showMenu = !showMenu"></burgerMenu>
          </div>
          <div
            v-if="showMenu"
            id="navbar-default"
            class="w-32 text-center k1:hidden bottom-0 right-0 absolute transform translate-y-full k1:w-auto"
            :class="{ hidden: !showMenu }"
            @click="() => (showMenu = false)"
          >
            <div
              class="flex flex-col p-1 bg-gray-50 rounded-lg border border-gray-200 md:flex-row k1:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
            >
              <router-link
                v-for="link in navigationLinks"
                :key="link.name"
                :to="link.path"
                :class="[
                  'block py-2 pr-4 pl-3 text-gray-700 rounded md:border-0 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent',
                  {
                    'hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700':
                      !(
                        $route.path === link.path ||
                        (link.path !== '/' &&
                          $route.path.startsWith(link.rootPath))
                      ),
                    'text-white bg-blue-700 hover:bg-gray-600':
                      $route.path === link.rootPath ||
                      (link.rootPath !== '/' &&
                        $route.path.startsWith(link.rootPath)),
                  },
                ]"
              >
                {{ link.name }}
              </router-link>

              <router-link
                v-if="user?.__isAppAdmin"
                @click="$router.push({ query: { modal: 'ManageAppUsers' } })"
                to="manageAppUsers"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                :class="{
                  'text-white bg-blue-700 hover:bg-gray-600':
                    $route.name === 'manageAppUsers',
                  'hover:bg-gray-200 hover:text-gray-700':
                    $route.name !== 'manageAppUsers',
                }"
              >
                Manage Users
              </router-link>
              <div
                @click="onLogout"
                v-if="account && checkScreenWidth"
                class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </nav>
    </template>
  </LSection>
</template>
<script>
import LSection from "@/components/layout/LSection.vue";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useUserPermissions } from "@/composables/useUserPermissions";
import { useStore } from "vuex";
import { LOGOUT } from "@/store/operations";
import { capitalize } from "@/lib/typeHelpers";
import BurgerMenu from "@/components/BurgerMenu.vue";

export default {
  components: {
    BurgerMenu,
    LSection,
  },
  name: "MenuBar",
  setup() {
    const { isLoggedIn, account, user } = useUserPermissions();
    const showMenu = ref(false);
    const store = useStore();
    const allLinks = ref([
      { name: "Home", path: "/", rootPath: "/" },
      { name: "About", path: "/about/us", rootPath: "/about" },
      { name: "Accords", path: "/accords/about", rootPath: "/accords" },
      { name: "Analysis", path: "/analysis", rootPath: "/analysis" },
      {
        name: "Education",
        path: "/education&training/europe-qt-community",
        rootPath: "/education&training",
      },
      { name: "Resources", path: "/playlist/page/1", rootPath: "/playlist" },
      { name: "News", path: "/news", rootPath: "/news" },
      { name: "Contact", path: "/contact", rootPath: "/contact" },
      // { name: "Impact", path: "/impact", rootPath: "/impact" },
    ]);

    const navigationLinks = computed(() => allLinks.value);
    const tabs = computed(() =>
      allLinks.value.filter((link) => link.name !== "Home")
    );

    const onLogout = () => {
      store.dispatch(LOGOUT);
    };
    // if the screen width is less than 1000px, show the menu
    const checkScreenWidth = () => {
      return window.innerWidth < 1000;
    };
    // Add closeMenu method
    const closeMenu = () => {
      showMenu.value = false;
    };
    // Split first word of account.name, but only when account.name exists
    const splitName = computed(() =>
      capitalize(account?.value?.name?.split(" ")[0] ?? "")
    );

    // Add onClickOutside method
    const onClickOutside = (event) => {
      const menuElement = event.target.closest(".menuBar");
      if (!menuElement) {
        closeMenu();
      }
    };

    // Add and remove the onClickOutside listener
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });

    return {
      isLoggedIn,
      onLogout,
      showMenu,
      account,
      splitName,
      user,
      tabs,
      checkScreenWidth,
      navigationLinks,
    };
  },
};
</script>

<style scoped></style>
