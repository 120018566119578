<template>
  <div class="emailAddress">
    <div class="w-50 md:w-auto lg:w-1/3">
      <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6 bg-gray-200 rounded-full p-1 m-2">
          <path stroke-linecap="round"
                d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"/>
        </svg>


        <TextInput
            :tabIndex="2"
            id="email"
            name="email"
            placeholder="Email address"
            @input="onInput"
            @change="onChange"
        ></TextInput>
      </div>
    </div>
  </div>
</template>
<script>
import TextInput from "@/components/forms/TextInput.vue";
import {useFieldValidation} from "@/composables/useFieldValidation";
import {ref} from "vue";

export default {
  name: "EmailAddress",
  emits: ["change", "input"],
  components: {
    TextInput,
  },
  setup(props, {emit}) {
    const error = ref("");

    const {validate} = useFieldValidation();
    const onInput = (value) => {
      error.value = validate(value);
      emit("input", value)
    };
    const onChange = value => emit("change", value);

    return {
      onInput,
      error,
      onChange,
    };
  },
};
</script>
